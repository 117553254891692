.dd-wrapper {
  position: relative;
}

.dd-label {
  font-size: 14px;
  color: #1f313d;
  font-weight: normal;
  margin-bottom: 6px;
}

.dd-list__group-label {
  text-transform: uppercase;
  color: #8e959f;
  font-size: 12px;
  padding: 14px 12px 10px;
  font-weight: 500;
}

.dd-clear-label {
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  margin: 0 8px;
  outline: none;
}

.dd-value-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
}

.dd-exclusion-default {
  display: none;
}

.dd-selected-value,
.dd-value {
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dd-selected-value--gray,
.dd-list__item--gray {
  color: #8e959f;
}

.dd-wrapper--disabled .dd-value-container {
  background-color: hsl(0, 0%, 95%);
  border-color: hsl(0, 0%, 90%);
  cursor: not-allowed;
  color: hsl(0, 0%, 50%);
  pointer-events: none;
}

.dd-value-container:hover {
  border: 1px solid #b3b3b3;
}

.dd-value-container--active {
  border: 1px solid #55a8fd;
}

.dd-list {
  top: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 4px 0;
  box-sizing: border-box;
}
.dd-list__wrapper {
  height: 100%;
  width: 100%;
  z-index: 1009;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
  margin-bottom: 8px;
  margin-top: 8px;
  padding-bottom: 4px;
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  position: absolute;
}

.dd-list__item {
  padding: 10px 12px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
}

.dd-list__item-border-bottom {
  border-bottom: 1px solid #ccc;
}

.dd-list__item:hover {
  background-color: #deebff;
}

.counter-badge {
  font-size: 14px;
  background-color: #f3f5f7;
  color: #1f313d;
  padding: 2px 8px;
  border-radius: 12px;
  cursor: pointer;
}

.dd-toggle-icon {
  color: #b0b0b0;
}

.dd-list__wrapper table {
  width: 100%;
}

.dd-wrapper--top-design {
  bottom: 36px;
}
