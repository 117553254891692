.provider-list {
  display: flex;
  flex-wrap: wrap;
}

.provider-list-item {
  padding: 4px 10px;
  background: #f3f5f7;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.provider-list-item--show-all {
  background: transparent;
  border: 1px solid #f3f5f7;
  color: blue;
}
