.claim-hisstory-flyover {
  background: #fff;
  position: fixed;
  right: 0;
  top: 58px;
  bottom: 0;
  width: 80%;
  z-index: 1002;
  box-shadow: -4px 0 3px 0 rgb(0 0 0 / 16%);
  overflow-y: scroll;
}

.claim-hisstory-flyover__title {
  color: #fff;
  background-color: #55a8fd;
  padding: 36px 20px 20px;
  font-size: 24px;
  font-weight: 700;
}

.claim-history-flyover__info {
  padding: 18px 24px;
  background-color: #e5e9ed;
  margin-bottom: 20px;
}

.claim-history-flyover__info table {
  width: 85%;
  border-collapse: collapse;
}

.claim-history-flyover__info table thead tr {
  color: #5e6871;
  font-weight: 700;
  font-size: 16px;
}

.claim-history-flyover__info table tbody tr {
  font-size: 14px;
}

.claim-history-flyover__hisory-table table {
  width: 100%;
  border-collapse: collapse;
}

.claim-history-flyover__hisory-table {
  padding: 0 20px;
}

.claim-history-flyover__hisory-table table tbody tr td {
  padding: 4px 0;
}

.claim-history-flyover__schedule-info {
  padding: 0 20px 20px;
}

.claim-history-flyover__hisory-table table tbody tr td .pill--xl {
  width: 120px;
}
