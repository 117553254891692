.submitted-package-container {
  display: flex;
}

.submitted-package {
  display: flex;
  background: #d9d9d9;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  justify-content: space-around;
  border-radius: 4px;
  cursor: pointer;
}

.potf-link {
  color: #000;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 14px;
  right: 0;
  cursor: pointer;
}

.submitted-package--disabled {
  opacity: 0.5;
}

.submitted-package:hover {
  background: #d9d9d994;
}

.delivery-status-icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.standalone-nav-icon {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.standalone-nav-loading {
  width: 18px;
  height: 18px;
  margin-right: 6px;
  position: relative;
}

.delivery-logs-status-col {
  position: absolute;
  left: 0;
  right: 0;
  padding: 12px;
  padding-left: 15px;
  top: 0;
  align-items: center;
  bottom: 0;
}

.delivery-logs-status-text {
  max-width: 70px;
}

.delivery-tracking-table .table-status__cell {
  padding: 4px 0;
}

.delivery-tracking-table .counter-wrapper-cell {
  position: absolute;
  left: 0px;
}

.delivery-tracking-table .status-circle {
  margin-left: 4px;
}

.delivery-logs-status-col--delivered {
  background-color: rgba(65, 179, 117, 0.1);
}

.delivery-logs-status-col--failed {
  background-color: #fff1f1;
}

.delivery-logs-status-col--transit {
  background-color: #ffff9e;
}

.delivery-logs-status-col--queue {
  background-color: #e5e9ed;
}

.delivery-logs-status-col--under-review {
  background-color: #fff1f1;
}

.submission-first-column {
  float: left;
  width: 50%;
}
.submission-second-column {
  float: right;
  width: 50%;
}
.submission-claimnumber--width-50 {
  width: 60%;
}
.submission-details-header {
  margin-top: 20px;
}

.standalone-submission-details {
  height: 180px;
}

.submission-details {
  width: 740px;
  min-height: 100px;
  max-width: 815px;
}

.submission-details-title {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2px;
  border-bottom: 1px solid #d0d9e2;
}

.table__filter-totaltitle--delivery .loadingblockhelper {
  left: 0;
  margin-right: 34px;
  top: 22px;
}
.dq-download_report-container {
  position: absolute;
  right: 0;
  top: -50px;
}

.dq-download_report {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  background-color: #ffffff;
  color: #7a8da4;
  height: 41px;
  min-width: 130px;
  margin-right: 0px !important;
}

.delivery-logs-notes-and-claim-search-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 94px;
}

.batch-created-appeal {
  animation: backgroundChange 3s forwards;
}

@keyframes backgroundChange {
  0% {
    background-color: #cef0de;
  }
  99% {
    background-color: #cef0de;
  }
  100% {
    background-color: transparent;
  }
}

.delivery-tracker__action-button {
  min-width: 20px;
  background-color: #d0d9e2;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  transition: background-color 0.3s;
  height: 30px;
}

.delivery-tracker__action-button--disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.delivery-tracker__action-button--active {
  background-color: #aeb9c5;
}

.delivery-tracker__action-button:hover {
  background-color: #aeb9c5;
}

.appealio-popup--v3.appealio-popup--v3-confirmation-popup.batch-confirmation-popup {
  min-width: 650px;
}

.batch-confirmation-popup .appealio-popup--v3__footer {
  background-color: #e5e9ed;
}

.appealio-popup--v3__footer .ap-button--batch--in-progress {
  background: #e5e9ed;
  border: 1px solid #1f313d;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1f313d;
  padding: 8px 12px;
}
