.flag-check-label {
  position: relative;
  cursor: pointer;
}

.flag-check-label input {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.reminder-icon-tooltip.flag-check-tooltip {
  width: 250px;
  font-size: 10px;
  padding: 8px;
  line-height: 19px;
}

.flag-check-label.flag-check-label--disabled {
  cursor: not-allowed;
}
