.appealio-table__action-bar--delivery-tracker .dropdown__container {
  width: 140px;
  margin-right: 12px;
}

.delivery-tracking-page-actions-wrapper {
  position: absolute;
  z-index: 1;
  top: -60px;
}

.delivery-tracking-table-container--lg .detail__container .rc-tabs-tab {
  display: none;
}

.delivery-tracking-table-container--lg
  .detail__container
  .rc-tabs-tab-active.rc-tabs-tab {
  display: block;
}
