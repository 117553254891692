.appealio-popup--v3 {
  min-width: 905px;
  background-color: #ffffff;
  border-radius: 15px;
  overflow: unset;
  max-height: 90vh;
}

.appealio-popup--v3--overflow-hidden {
  overflow: hidden;
}

.appealio-popup--v3--overflow-hidden .appealio-popup--v3__content {
  padding-bottom: 110px;
  overflow-y: scroll;
}

.appealio-popup--v3--overflow-hidden .appealio-popup--v3__footer--inside {
  margin: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.appealio-popup--v3__title-wrapper,
.appealio-popup--v3__footer {
  background-color: #1f313d;
  padding: 12px 48px;
}

.appealio-popup--v3-confirmation-popup .appealio-popup--v3__title-wrapper {
  padding: 20px 48px;
}

.appealio-popup--v3__title-wrapper {
  display: flex;
  align-items: center;
  border-radius: 15px 15px 0 0;
}

.appealio-popup--v3__title {
  font: normal normal 400 18px/24px 'Inter', sans-serif;
  color: #ffffff;
  text-align: center;
}

.appealio-popup--v3__close {
  width: 16px;
  height: auto;
  margin-left: auto;
  cursor: pointer;
}

.appealio-popup--v3__content {
  padding: 30px 48px;
}

.appealio-popup--v3__footer {
  border-radius: 0 0 15px 15px;
}

.appealio-popup--v3__footer--inside {
  margin: 48px -48px -36px;
  position: absolute;
  bottom: 0;
  left: 48px;
  right: 48px;
}

.appealio-popup--v3__footer .ap-button {
  padding: 10px 60px;
  margin-left: auto;
}

form .textfield__input {
  margin-bottom: 0;
}

form .textfield__label {
  font-size: 14px;
  color: #1f313d;
  font-weight: normal;
}

.successful-submission-pm-note button {
  width: 150px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
}

.successful-submission-pm-note button img {
  width: 32px;
}
