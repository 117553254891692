.auto-fill-btn {
  margin-bottom: 8px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  border-radius: 10px;
  padding: 2px 4px;
  font-size: 12px;
}

.auto-fill-btn.auto-fill-btn--active {
  background: #55a8fd;
  color: #ffffff;
  border: 1px solid #55a8fd;
}
