.upload-era-document-wrapper .documents__description {
  font-weight: 600;
}

.upload-era-document-wrapper .documents__box {
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.upload-era-document-wrapper .file_list {
  width: 80%;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.upload-era-document-wrapper .file-download-wrapper {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border-top: 1px solid #ccc;
  padding-top: 26px;
}

.upload-era-document-wrapper .select-file--disabled {
  width: 100%;
  pointer-events: none;
}

.upload-era-document-wrapper .file_button--download {
  width: 100%;
  text-align: center;
  border-radius: 3px;
  background: #1d72f2;
  pointer-events: visible;
}

.ap-file-popup {
  max-width: 460px;
  width: 100%;
  padding: 16px 32px;
  border-radius: 10px;
  overflow: unset;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
}

.ap-file-popup__content {
  padding: 32px 0;
}

.ap-file-popup__title {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.alert-dialog__close-icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}

.ap-file-popup__footer button {
  justify-content: center;
}
