.clinic-billing-provider-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1rem;
  border-top: 1px solid #d0d9e2;
  margin-top: 26px;
  padding-top: 10px;
  padding-bottom: 6px;
}

.clinic-billing-provider-practice-title {
  font-weight: 600;
  margin-bottom: 8px;
}

.clinic-billing-provider-container {
  padding-bottom: 10px;
  padding-top: 10px;

  border-bottom: 1px solid #cfd7df;
}

.rendering-provider-form {
  overflow-y: auto;
}

.appealio-popup--v3__content {
  padding: 30px 48px;
  overflow-y: unset;
  max-height: 90vh;
}

/* Scrollbar */
.appealio-popup--v3__content::-webkit-scrollbar {
  width: 8px;
}

.appealio-popup--v3__content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c4c4c4;
}

.download-csv--popup {
  max-width: 600px !important;
  min-height: auto;
  overflow: visible;
  width: 800px !important;
  padding: 0px;
}
