.batch-submission-widget-popup {
  min-width: 1380px !important;
  min-height: 80%;
  height: 100%;
}

.batch-submission-widget-popup .appealio-popup-content {
  padding-top: 0;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.batch-submission-widget-popup .codes-info-popup .appealio-popup-content {
  padding: 20px 32px 30px;
}

.batch-submission-popup-title {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  width: 100%;
}

.batch-submission-popup-title__details {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}

.batch-submission-popup-title__details__payer {
  margin: 0 50px;
  text-decoration: underline;
}

.batch-submission-widget-popup .denied-claims-filter__row .dd-label {
  color: #a7b0bc;
  margin-bottom: 6px;
  font-size: 12px;
  font-weight: 700;
}

.batch-submission-widget-popup .denied-claims-filter__row {
  margin-top: 8px;
}

.batch-submission-widget-popup .dd-value-container {
  height: 42px;
}
