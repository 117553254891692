.background-color-grey-1 {
  background-color: #f3f4f6;
}
.delivery-tracking-container {
  height: calc(100% - 58px);
}

.delivery-tracker-filter-container {
  width: 1310px;
  min-width: 1280px;
  margin: 0 auto;
  padding-top: 30px;
}

.delivery-tracker-filter-container--lg {
  width: 1400px;
}

.delivery-tracking-table-container {
  min-height: calc(100vh - 168px);
  width: 1310px;
  min-width: 1280px;
  padding-top: 28px;
  margin: 0 auto;
}

.delivery-tracking-table-container--lg {
  width: 1400px;
  padding-top: 0;
  position: relative;
}

.delivery-tracking-table-container--lg .filter-wrapper__search-box {
  width: 270px;
}

.delivery-tracking-table-container--lg .filter-wrapper__search-box input {
  width: 100%;
  height: 40px;
  border-radius: 4px 0 0 4px;
}

.delivery-tracking-table-container--lg
  .filter-wrapper__search-box
  input::placeholder {
  font-size: 12px;
}

.delivery-tracking-table-container .appealio-table tbody td {
  padding: 8px;
}

.delivery-tracking-table-container .appealio-table thead th {
  padding: 8px;
}
.delivery-tracking-table-container .appealio-table thead th:first-child {
  padding-left: 30px;
}

.submission-details-status-success {
  color: green;
}

.submission-details-status-failed {
  color: red;
}

.submission-details-header p {
  display: flex;
  column-gap: 4px;
}

.submission-details-header label {
  font-weight: bold;
}

.error-message {
  color: red;
  text-align: center;
}

.dashboard_header-warning {
  padding-left: 800px;
  font-weight: bold;
  color: red;
}

.submission-message {
  padding-left: 516px;
  font-weight: bold;
  color: red;
  font-size: 17px;
}

.delivery-tracker-filter-container .download-csv-wrapper .loading-indicator {
  top: 0;
  left: -12%;
}
.delivery-tracker-filter-container .dropdown__container {
  width: 140px;
}

.delivery-tracker-filter-container .filter-wrapper__search-box {
  max-width: 290px;
}

.delivery-tracker-filter-container .filter-wrapper__search-box input {
  width: 100%;
}

.delivery-tracker-filter-container .SingleDatePicker .DateInput {
  width: 114px;
}

.delivery-tracker-filter-container
  .SingleDatePicker
  .SingleDatePickerInput__withBorder {
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 40px;
}

.delivery-tracker__status-cell-value,
.delivery-tracker__type-header {
  margin-left: 26px;
}
