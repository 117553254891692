.ap-input-container {
  display: flex;
  flex-direction: column;
}

.ap-input-label {
  font-size: 14px;
  color: #1f313d;
  font-weight: normal;
  display: inline-flex;
}

.ap-input {
  /* White */
  background: #ffffff;
  /* Grey 3 */

  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 8px 12px;
  font-size: 14px;
}

.ap-input--include-page {
  margin-bottom: 8px;
  max-width: 300px;
  width: 100%;
}

.ap-input--include-page::placeholder {
  font-size: 12px;
}

.ap-input-error {
  font-size: 12px;
  color: red;
}

.ap-input--disabled {
  background-color: #f3f3f3;
  border: solid 2px #dedede;
}

.ap-input--error {
  border-color: red;
}

.input-search-box {
  width: 100%;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0;
  padding-left: 12px;
}

.input-search-box input {
  width: 100%;
  padding: 9px;
  border: 0;
  background: #ffffff;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.input-search-box input::placeholder {
  color: #8e959f;
}

.input-search-box--clear {
  margin-right: 8px;
  cursor: pointer;
  position: absolute;
  right: 0;
  background-color: #ffffff;
}
