.denials-overview__heading {
  color: #000;
  font-size: 24px;
  font-weight: 500;
}

.system-info-container {
  width: 1310px;
  min-width: 1280px;
  margin: 0 auto;
  padding-top: 30px;
  color: #000000;
}

.system-info {
  margin: 0 auto;
  min-width: 1280px;
  width: 1310px;
}

.system-overview__card {
  background-color: #e5e9ed;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px;
  padding-bottom: 14px;
  border-radius: 10px;
}

.system-overview__card-heading {
  color: #000000;
  width: 155px;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.system-overview__widget {
  background-color: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 6px;
  padding: 8px 16px;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #000;
  display: flex;
  align-items: center;
}

.system-overview__widget-actions {
  background-color: #ffffff;
  border: 1px solid #f6f6f6;
  border-radius: 6px;
  padding: 16px;
  margin-right: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #000;
  overflow: hidden;
}

.system-overview__line {
  width: 2px;
  background-color: #aeb9c5;
  height: 60px;
  margin-right: 20px;
}

.system-overview__widget-count {
  font-size: 24px;
  line-height: 19px;
  color: #1f313d;
}

.system-overview__widget-icon {
  display: flex;
  height: 20px;
  gap: 6px;
}

.system_overview__action-assigned-widget {
  min-width: 500px;
  display: flex;
}

.denial-analytics__date-item {
  width: 210px;
}

.denial-analytics__date-item .SingleDatePicker .DateInput_input {
  height: 40px;
}
