.stepper-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 600px;
  margin-left: auto;
}

.step-indicator {
  height: 20px;
  width: 20px;
  background-color: #d0d9e2;
  border-radius: 50%;
  border: 1px solid #e5e9ed;
  position: relative;
}

.step-indicator--active {
  background-color: #1c72f2;
}

.step-indicator::after {
  content: '';
  height: 1px;
  background-color: #d0d9e2;
  width: 140px;
  position: absolute;
  left: 100%;
  top: 50%;
}

.step-indicator:last-child::after {
  display: none;
}
