.accordion-item__title {
  font-weight: 600;
  margin-bottom: 6px;
}

.accordion-item__heading {
  background-color: #e5e9ed;
  padding: 8px 16px;
  border-radius: 4px;
  margin: 0px;
  cursor: pointer;
}

.accordion-item {
  border: 1px solid #e5e9ed;
  margin-bottom: 16px;
  border-radius: 4px;
}

.accordion-content {
  max-height: 0;
  padding: 0;
  overflow: hidden;
}

.accordion-content--open {
  max-height: 100%;
  padding: 8px 16px;
  overflow: unset;
}
