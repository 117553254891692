.alert-dialog {
  width: auto;
  height: auto;
  background-color: #fff;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
  margin: auto;
  z-index: 1002;
  position: fixed;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 60px 40px;
  border-radius: 4px;
}

.alert-dialog__close-btn {
  text-align: right;
  position: relative;
  top: -40px;
  right: -20px;
}

.alert-dialog__status-icon {
  text-align: center;
}

.alert-dialog__content {
  font-size: 18px;
  text-align: center;
  color: #002841;
  margin: auto;
  margin-top: 20px;
}

.import-rule-practice-agent-permission .alert-dialog__content {
  white-space: break-spaces;
  text-align: left;
  margin-top: 0;
}

.alert-dialog.import-rule-practice-agent-permission {
  min-width: 640px;
}

.import-rule-practice-agent-permission__buttons-wrapper {
  position: absolute;
  display: inline-flex;
  margin-bottom: 0;
  right: 32px;
}

.progressbar-popup {
  width: 700px;
  background: #ffffff;
  position: absolute;
  left: 50%;
  top: 50%;
  padding: 32px;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressbar-popup__content {
  width: 100%;
}

.progressbar-popup__content .progress {
  padding-left: 0;
  position: relative;
}

.progressbar-popup .progress-value {
  background: #55a8fd;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #55a8fd;
  height: 16px;
  animation: 15s normal forwards;
  position: relative;
  transition: width 0.3s linear;
}

.progress-percentage {
  position: absolute;
  top: 0;
  font-weight: 700;
  color: #fff;
  font-size: 12px;
  padding-left: 10px;
  right: 16px;
}

.progress-bar-screen-dimmer {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.5s ease-in-out, z-index 0.5s ease-in-out;
}

.progress-bar-screen-dimmer--show {
  opacity: 1;
  z-index: 1004;
}
