.file-upload-info-text {
  text-align: center;
  color: #5e6871;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 32px;
  margin-top: 8px;
}

.file-upload-box {
  border-radius: 8px;
  background-color: white;
  border: solid 1px #e6ecef;
  margin-bottom: 16px;
  padding: 32px;
  min-height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file-upload-box--has-file {
  justify-content: start;
}

.file-upload-file-icon {
  width: 46px;
  float: left;
  height: 100%;
}

.file-upload-description {
  font-size: 12px;
  text-align: center;
}

.file-upload-error-msg-container {
  margin-top: -30px;
  text-align: center;
  margin-bottom: 20px;
}
.file__item-name {
  width: 80%;
  display: inline-block;
}
.file__item-cancel {
  width: 20%;
  display: inline-block;
  text-align: right;
  font-size: 12px;
  color: #5e6871;
}
.file__item-cancel img {
  cursor: pointer;
  padding-right: 8px;
}
