.file-dropzone {
  width: 100%;
}

.dropzone {
  border: 1px solid #e6ecef;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 0.1s ease;
}

.dropzone:hover {
  border-color: #afbbc3;
  background-color: #f0ffff;
}

.dropzone-active {
  border-color: #2196f3;
  background-color: #f0ffff;
}

.dropzone p {
  margin: 0;
  font-size: 16px;
  color: #666;
}

.file-list {
  margin-top: 20px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-bottom: 10px;
}

.file-name {
  font-size: 14px;
  color: #333;
}

.remove-file {
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.remove-file:hover {
  background-color: #ff7875;
}

.delete-document-button {
  border: none;
  background: none;
}

.delete-document-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
  display: block;
}
