.status-tracker-checkbox {
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
  padding: 4px 4px;
  border: 1px solid transparent;
  border-radius: 25px;
  transition: all 0.2s ease-in-out;
}
.status-tracker-checkbox--default-border {
  border: 1px solid #aeb9c5;
}
.status-tracker-checkbox__icon {
  background: #f3f5f7;
  border-radius: 30px;
  height: 26px;
  width: 26px;
}
.status-tracker-checkbox:hover {
  border: 1px solid #41b275;
}
.status-tracker-checkbox--checked {
  border: 1px solid #41b275;
}
.status-tracker-checkbox__title {
  padding: 0px 4px;
}
