.global-search-container {
  position: absolute;
  right: 350px;
  padding-left: 30px;
  /* Added to handle mouse select issue */
  top: 56px;
  z-index: 1004;
}

.global-search-box {
  min-width: 580px !important;
}

.global-search-box .search-box__input {
  width: 474px;
}
