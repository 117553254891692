.batch-submission-file-dropzone {
  border: 1px transparent dashed;
  max-width: 180px;
}

.batch-submission-file-dropzone--drag-over {
  border-color: #afbbc3;
  background-color: #f0ffff;
}

.batch-submission-file-dropzone__button {
  background: #e5e9ed;
  color: #0b283f;
  height: 42px;
  font-weight: 350;
}

.batch-submission-file-dropzone__button-container {
  display: flex;
  align-items: center;
}

.batch-submission-file-dropzone__button-container .file-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.batch-submission-file-dropzone__button.batch-submission-file-dropzone__button--sm {
  width: 30px;
}

.batch-submission-file-dropzone__button.batch-submission-file-dropzone__button--lg {
  width: 180px;
  padding-left: 20px;
}
