.ap-select-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ap-select-label {
  font-size: 14px;
  color: #1f313d;
  font-weight: normal;
}

.ap-select {
  /* White */
  background: #ffffff;
  /* Grey 3 */

  /* border: 1px solid #D0D9E2; */
  box-sizing: border-box;
  /* border-radius: 4px; */

  font-size: 14px;
}

.ap-select > .css-unqvcz {
  border: 1px solid #d0d9e2;
}

/* Custom styles */
.appeal-letter__dropdown__group.css-syji7d-Group:nth-child(2) {
  padding-top: 16px;
  border-top: 1px solid hsl(0, 0%, 87%);
}

.ap-select--error .ap-select-v2__control {
  border: 1px solid red;
}

.ap-select--focus {
  border: 1px solid blue;
  border-radius: 4px;
}

.ap-select-tooltip {
  border-radius: 2px;
  color: rgb(51, 51, 51);
  font-size: 85%;
  overflow: hidden;
  padding: 3px 3px 3px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
