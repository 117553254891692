.fee-schedule-table {
  width: 100%;
  border-collapse: collapse;
}

.fee-schedule-table th.fee-schedule-table__th,
.fee-schedule-table td.fee-schedule-table__td {
  padding: 16px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background: #fff;
  border: none;
  white-space: nowrap;
}

.fee-schedule-table
  .fee-schedule-table__td.fee-schedule-table__th__allowed-amount {
  max-width: 120px;
}

.fee-schedule-table__th__allowed-amount .ap-input {
  max-width: 80%;
}

.fee-schedule-table th.fee-schedule-table__th {
  background-color: #f3f5f7;
  position: sticky;
  top: 0;
  z-index: 100;
  font-size: 14px;
  white-space: nowrap;
  font-weight: 400;
  padding-top: 0;
}

.fee-schedule-table tbody tr.fee-schedule-table__tr th:first-child,
.fee-schedule-table tbody tr.fee-schedule-table__tr td:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.fee-schedule-table tbody tr.fee-schedule-table__tr th:last-child,
.fee-schedule-table tbody tr.fee-schedule-table__tr td:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.fee-schedule-table .spacer td {
  border: none;
  height: 8px;
  background-color: transparent;
  padding: 0;
}
