.autocomplete-input-container {
  display: flex;
  flex-direction: column;
}

.autocomplete-input-container input {
  width: 100%;
  /* White */
  background: #ffffff;
  /* Grey 3 */

  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 8px 12px;
  font-size: 14px;
}

.autocomplete-option {
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
}

.autocomplete-option-highlighted {
  background-color: #f5f9fc;
}

.autocomplete-input-container div div:nth-child(2) {
  z-index: 50;
}
