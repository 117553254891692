.cms-merge-tool-title {
  font-size: 20px;
  font-weight: 500;
}

.cms-merge-tool-description {
  color: #1f313d;
  font-size: 14px;
}

.title-card {
  padding: 16px;
  flex: 1;
  border: 1px solid #e5e9ed;
  transition: flex 0.3s ease-in-out;
  border-radius: 10px;
  transition: all 0.3s ease;
  background-color: #ffffff;
}

.title-card h1 {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  margin: 0px;
  margin-bottom: 12px;
}

.title-card.disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
  background-color: #e5e9ed;
}

.cms-merge-tool-wrapper {
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
  margin-top: 40px;
}

.download-cms-1500-btn {
  padding: 8px 16px;
  color: white;
  position: relative;
  min-height: 38px;
  min-width: 100px;
  background-color: #1c72f2;
}

.opt-text-color {
  color: #aeb9c5;
}

.cms-file-upload-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  margin-left: 8px;
}

.cms-merge-tool-upload-wrapper {
  margin: 24px 0px;
  background-color: #ffffff;
  padding: 22px 24px;
  min-height: 100px;
  border-radius: 10px;
  transition: background-color 0.5s;
  border: 2px dashed #e5e9ed;
}

.cms-merge-tool-upload-wrapper--dragover {
  box-shadow: 0 8px 16px rgba(98, 58, 58, 0.2);
}

.cms-merge-tool-upload-wrapper--disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: not-allowed;
  background-color: transparent;
  border: 2px dashed transparent;
}

.cms-1500-form-upload-file {
  height: 330px;
  display: flex;
  align-items: center;
  justify-content: center;
}
