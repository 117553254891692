.animation-container {
  display: flex;
  align-items: center;
}

.dot-container {
  display: flex;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #d5c4c4;
  border-radius: 50%;
  margin: 0 2px;
  animation: dotsAnimation 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes dotsAnimation {
  0%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
