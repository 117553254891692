.appealio-table__action-bar .search-box {
  position: absolute;
}

.search-box {
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 2px solid #0b283f;
  border-radius: 5px;
  min-width: 364px;
  height: 42px;
}

.search-box__icon {
  background: #0b283f;
  font-size: 18px;
  color: #ffffff;
  padding: 6px;
  border-radius: 5px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
}

.search-box__input {
  padding: 8px;
  border: 0;
  width: 260px;
}

.search-box__form {
  display: flex;
  align-items: center;
  height: 42px;
}

.search-box__submit-btn {
  color: #ffffff;
  background: #0b283f;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  outline: none;
  height: 32px;
  margin-right: 4px;
  display: flex;
  align-items: center;
}

.search-box__submit-btn:hover {
  color: #ffffff;
}

.btn.search-box__submit-btn:focus {
  outline: none;
  color: #ffffff;
}
