.csv-claims-import-confirm-popup__title {
  padding-left: 32px !important;
}
.csv-claims-import-confirm-popup__icon {
  height: 36px;
  width: 36px;
}

.csv-claims-import-confirm-popup__info {
  font-size: 14px;
}

.csv-claims-import-confirm-popup__confirm-text {
  margin-left: 36px;
  font-weight: bold;
}

.csv-claims-import-confirm-popup-close-cancel-btn {
  background: #aeb9c5;
  color: black;
}
