.sop-info-tooltip-background {
  background-color: #0a283d !important;
  border-radius: 10px !important;
}
.sop-info-tooltip {
  background-color: #0a283d !important;
  color: white !important;
}

.sop-info-tooltip-title {
  padding-top: 10px;
  font-size: 14px;
}

.sop-info-tooltip-subtitle {
  font-size: 10px;
  margin-bottom: 12px;
}

.sop-info-tooltip-background {
  background-color: #333;
  opacity: 0.9;
}

.sop-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
