.loading-bar-container {
  position: absolute;
  left: 10%;
  right: 10%;
  top: 50%;
}

.progress {
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 5px;
  display: flex;
  height: 16px;
}

.progress-value {
  animation: load 15s normal forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #55a8fd;
  height: 16px;
  width: 0;
}

@keyframes load {
  0% {
    width: 0;
  }

  10% {
    width: 20%;
  }

  100% {
    width: 94%;
  }
}
