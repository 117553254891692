input[type='checkbox'].toggle:focus {
  outline: 0;
}

input[type='checkbox'].toggle {
  margin: 0 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.toggle-option {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 0;
}

.toggle {
  height: 32px;
  width: 52px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  background: #1c72f2;
  transition: all 0.2s ease;
}

.toggle-wrapper--off .toggle {
  background-color: #e5e9ed;
}

.toggle::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
  transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
}

.toggle:checked::after {
  transform: translateX(20px);
}
