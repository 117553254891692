.indicator-container {
  display: flex;
  align-items: center;
}

.indicator-dot {
  width: 8px;
  height: 8px;
  background-color: #e5e9ed;
  border-radius: 50%;
  margin-right: 2px;
}

.indicator-dot--visited {
  background-color: #6da4f5;
}

.indicator-dot--active {
  background-color: #1c72f2;
}
