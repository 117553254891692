.pill {
  font-size: 12px;
  margin-right: 8px;
  padding: 0 8px;
  height: 25px;
  border-radius: 26px;
  width: 100px;
  background-color: #fff;
  border: 2px solid #e5e9ed;
  background-color: #e5e9ed;
  color: #5e6871;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pill:last-child {
  margin-right: 0;
}

.pill--outline {
  border: 1px solid #5e6871;
  color: #5e6871;
  background-color: #fff;
}

.pill--outline:hover {
  border: none;
  box-shadow: inset 0 5px 10px hsla(0, 0%, 100%, 0.35),
    inset 0 -5px 10px rgba(0, 0, 0, 0.25);
}

.pill--outline-active {
  border: none;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  background: #e5e9ed;
}

.pill--info {
  background-color: #1c72f2;
  color: #ffffff;
  border: none;
}

.pill--info-outline {
  border: 1px solid #1c72f2;
  color: #1c72f2;
  background-color: #fff;
}

.pill--info-outline:hover {
  color: #fff;
  border: none;
  background: #1c72f2;
  box-shadow: inset 0 5px 10px #1fbcff, inset 0 -5px 10px #10438f;
}

.pill--info-outline-active {
  color: #fff;
  border: none;
  background: #1c72f2;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}

.pill--success {
  background-color: #41b375;
  color: #ffffff;
  border: none;
}

.pill--success-outline {
  border: 1px solid #41b375;
  color: #41b375;
  background-color: #fff;
}

.pill--success-outline:hover {
  border: none;
  color: #fff;
  background: #41b375;
  box-shadow: inset 0 5px 10px #1be678, inset 0 -5px 10px rgba(0, 0, 0, 0.25);
}

.pill--success-outline-active {
  color: #fff;
  border: none;
  background: #41b375;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}

.pill--danger {
  background-color: #da4052;
  color: #ffffff;
  border: none;
}

.pill--danger-outline {
  border: 1px solid #da4052;
  color: #da4052;
  background-color: #fff;
}

.pill--danger-outline:hover {
  border: none;
  color: #fff;
  background: #da4052;
  box-shadow: inset 0 5px 10px #f91d6c, inset 0 -5px 10px #9c1424;
}

.pill--danger-outline-active {
  color: #fff;
  border: none;
  background: #da4052;
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
}
