.column-sort-arrow {
  font-size: 20px;
  cursor: pointer;
}

.no-of-uses-info-btn {
  color: #007bff;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.column-pointer {
  cursor: pointer;
}

.column-bold {
  font-weight: bold;
}

.column-sort-arrow {
  font-size: 16px;
}

.no-of-uses-col {
  width: 60px;
}

.no-of-uses-col--value {
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}
