.status-circle {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 1000%;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-circle--denied {
  border-color: #da4052;
  color: #da4052;
}

.status-circle--successful {
  border-color: #41b275;
  color: #41b275;
}
