.date-input .textfield__label {
  font-size: 14px !important;
  color: #1f313d;
  font-weight: normal;
}

.date-input .textfield__input {
  background: #ffffff;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  margin-bottom: 0px;
}

.right-border-grey {
  border-right: 1px solid #d0d9e2;
}

.masked-input-icon {
  position: absolute;
  top: 36px;
  right: 26px;
  color: gray;
}

.latest-sop-activity-at {
  font-size: 9px;
  color: #1f313d;
  font-weight: normal;
  text-align: center;
  margin-top: 4px;
}
