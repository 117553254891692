.pagination {
  display: flex;
  align-items: center;
}

.appealio-table__pagination {
  margin: 0;
}

.appealio-table__pagination.pagination > li {
  outline: 0;
}
.appealio-table__pagination.pagination > .active > a,
.appealio-table__pagination.pagination > .active > a:focus,
.appealio-table__pagination.pagination > .active > a:hover,
.appealio-table__pagination.pagination > .active > span,
.appealio-table__pagination.pagination > .active > span:focus,
.appealio-table__pagination.pagination > .active > span:hover {
  background-color: #1f313d;
  border-color: #1f313d;
  color: #ffffff;
  z-index: 0;
}

.appealio-table__pagination.pagination > li > a,
.appealio-table__pagination.pagination > li > span {
  color: #1f313d;
  outline: 0;
  z-index: 0;
}

.pagination-input {
  margin-left: 16px;
}
.pagination-input__label {
  font-size: 14px;
  font-weight: 600;
  color: #a7b0bc;
  margin-right: 6px;
}
.pagination-input__number-input {
  font-size: 14px;
  font-weight: 600;
  color: #a7b0bc;
  margin-right: 2px;
  border: 2px solid #e6ecef;
  border-radius: 4px;
  padding: 4px;
}
