.appealio-popup--v3.reminder-popup {
  max-height: 600px;
  min-width: 742px;
  min-height: 400px;
}

.appealio-popup--v3.reminder-popup .ap-input--disabled {
  border: 1px solid #dedede;
}

.reminder-popup .ap-button {
  padding: 8px;
  color: #fff;
  font: 400 14px/1.267 'Inter', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;

  min-width: 160px;
  justify-content: center;
}
