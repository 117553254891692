.rule-status-select {
  width: 100%;
}

.rule-status-select > .css-13cymwt-control {
  border-radius: 25px;
}

.rule-status-select > .css-1s2u09g-control,
.rule-status-select > .rule-select__control {
  border-radius: 25px;
  background-color: white;
  width: 80px;
}

.rule-status-select-dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  background-color: #2ecc71;
}

.rule-status-select-dot--active {
  background-color: #41b275;
}

.rule-status-select-dot--paused {
  background-color: #ffff00;
}
