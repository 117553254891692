.claim-table-container {
  padding-bottom: 20px;
}

.claim-table {
  width: 100%;
}

.claim-table th {
  font-size: 12px;
  color: #1f313d;
  border-bottom: 1px solid #e5e9ed;
  padding: 2px 8px;
  font-weight: 400;
}

.claim-table td {
  font-size: 14px;
  color: #1f313d;
  font-weight: 350;
  padding: 4px 8px;
  border-bottom: 1px solid #e5e9ed;
}

.claim-table tr {
  transition: background-color 0.3s;
}

.claim-table tbody tr:hover {
  background-color: #1c72f217;
}

.claim-table .selected {
  background-color: #1c72f217;
}

.claim-table__select-button {
  border: none;
  background-color: transparent;
}

.claim-table__col-select {
  width: 50px;
}

.claim-table__col-patient {
  width: 110px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.claim-table__col-claim-id {
  width: 110px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.claim-table__col-service-date {
  width: 110px;
  max-width: 110px;
}

.claim-table__col-eob-date {
  width: 100px;
  max-width: 100px;
}

.claim-table__col-amounts {
  width: 120px;
  max-width: 120px;
}

.claim-table__col-amounts div {
  width: 120px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.claim-table__col-practice {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.claim-table__col-practice div {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.claim-table__col-procedures,
.claim-table__col-modifiers,
.claim-table__col-reasons,
.claim-table__col-remarks {
  width: 90px;
  max-width: 90px;
}

.pagination-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  background: #fff;
}

.total-claims {
  color: #667380;
  font-size: 14px;
  font-weight: 700;
}

.claim-table__empty {
  width: 100%;
  height: 200px;
}

.claim-table .claim-table__empty-td {
  border: 0;
}

.claim-table tr.claim-table__empty-tr:hover {
  background-color: transparent;
}

.batch-submisison-claim-table tbody tr:hover .dd-list__wrapper tr:hover {
  background-color: transparent;
}

.batch-submission-claim-table td .dd-list__wrapper td {
  padding: 0 !important;
}

.batch-submission-widget-popup .dropdown__container {
  min-width: 80px;
}

.batch-submission-date-filter .SingleDatePickerInput {
  padding-right: 0;
  display: flex;
}

.batch-submission-date-filter .SingleDatePicker .DateInput {
  width: 110px;
}
.batch-submission-date-filter .SingleDatePicker .DateInput_input {
  padding: 7px 12px;
}

.batch-submission-date-filter .SingleDatePicker {
  border-color: #d0d9e2;
  border-radius: 4px;
}

.batch-submission-date-filter
  .SingleDatePicker
  .SingleDatePickerInput_clearDate {
  right: 20px;
  background: transparent;
  cursor: pointer;
}
