.auto-reminder-toggle .toggle {
  height: 20px;
  width: 34px;
  border-radius: 10px;
  margin: 0 0 !important;
  background-color: #e0e0e0;
}

.auto-reminder-toggle .toggle-option {
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 2px;
  margin-right: 2px;
}

.auto-reminder-toggle .toggle:checked {
  background-color: #1c72f2;
}

.auto-reminder-toggle .toggle::after {
  width: 14px;
  height: 14px;
  top: 3px;
  left: 2px;
}

.auto-reminder-toggle .toggle:checked::after {
  transform: translateX(16px);
}

.auto-reminder-toggle .toggle-option {
  font-size: 16px;
  line-height: 20px;
}
