.status-schedule-info {
  background: #ffffff;
  border: 2px solid #41b375;
  border-radius: 10px;
  padding: 16px 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.status-schedule-info-table thead th {
  font: normal normal 700 14px/19px 'Inter', sans-serif;
  color: #5e6871;
}

.status-schedule-info-table tbody td {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.status-schedule-info-table td,
.status-schedule-info-table th {
  padding-right: 24px;
}

status-schedule-info-table td:last-child,
.status-schedule-info-table th:last-child {
  padding-right: 0;
}

.status-schedule-info__action button {
  padding: 8px 16px;
  border: 1px solid #d0d9e2;
  border-radius: 5px;
  background-color: #ffffff;
  color: #0b283f;
  font: normal normal 400 14px/19px 'Inter', sans-serif;
  margin-right: 16px;
  min-width: 132px;
}

.status-schedule-info__action button:hover {
  border-color: #0b283f;
}

.status-schedule-info__action button:last-child {
  margin-right: 0;
}

.status-schedule-info__icon {
  position: absolute;
  left: 0;
  transform: translateX(-50%);
}
