.global-search-v2__search-result-table-container {
  margin: 0 -16px;
}

.global-search-v2__search-result-table {
  width: 100%;
  border-collapse: collapse;
}

.global-search-v2__search-result-table__header-cell {
  padding: 6px;
  text-align: left;
  font: normal 500 10px/19px 'Inter', sans-serif;
  color: #5e6871;
  white-space: nowrap;
}

.global-search-v2__search-result-table__data-row {
  cursor: pointer;
}

.global-search-v2__search-result-table__data-row:hover {
  background-color: #e5e9ed;
}

.global-search-v2__search-result-table__data-td {
  padding: 6px;
  font: normal 400 14px/19px 'Inter', sans-serif;
  color: #000;
  max-width: 150px;
}

.global-search-v2__search-result-table__header-cell:first-child,
.global-search-v2__search-result-table__data-td:first-child {
  padding-left: 16px;
}

.global-search-v2__search-result-table__patient-cell {
  max-width: 120px !important;
}

.global-search-v2__search-result-table__action-reason-cell {
  max-width: 100px;
}

.action-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 6px 12px;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #0056b3;
}
