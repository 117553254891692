.patient-cost-estimator {
  display: flex;
  flex: 1;
  background-color: #f3f4f6;
  height: calc(100% - 58px);
  padding-top: 1em;
  padding-bottom: 1em;
  flex-direction: column;
}

.patient-cost-estimator__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
}

.patient-cost-estimator .container {
  background-color: white;
  border-radius: 10px;
  justify-content: center;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
  width: 100%;
  padding: 16px 44px;
}

.patient-cost-estimator .card-container {
  padding: 40px;
}

.patient-cost-estimator .textfield__label {
  color: #000000;
  margin-bottom: 10px;
}

.patient-cost-estimator button {
  background-color: #0b283f;
  color: white;
  border-radius: 4px;
  border: none;
  height: auto;
  padding: 0.75rem 3.375rem;
}

.patient-cost-estimator .update-benefit-popup .ap-button--light {
  background: rgba(255, 255, 255, 0.0001);
  color: #1f313d;
  border: 1px solid #d0d9e2;
}

.create-estimate-nav-button {
  padding-right: 0.5em;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.patient-cost-estimator button .submit__button--icon {
  height: 1.5em;
  width: 1.5em;
  filter: invert(1%) sepia(100%) saturate(0%) hue-rotate(143deg)
    brightness(120%) contrast(120%);
}

.cost-estimator-estimates-table,
.cost-estimator-create-estimates-table {
  width: 100%;
  background: white;
  border-radius: 4px;
}

.cost-estimator-estimates-table thead,
.cost-estimator-create-estimates-table thead {
  height: 4em;
}

.cost-estimator-create-estimates-table table {
  margin-left: -10px;
}

.cost-estimator-estimates-table thead th,
.cost-estimator-create-estimates-table thead th {
  padding: 1rem;
  font-size: 10px;
  font-weight: bold;
  color: #5e6871;
}

.cost-estimator-estimates-table tbody tr,
.cost-estimator-create-estimates-table tbody tr {
  height: 4em;
}

.cost-estimator-estimates-table tbody tr td:first-child {
  border-right: 4px solid #f3f4f6;
}

.cost-estimator-estimates-table tbody td,
.cost-estimator-create-estimates-table tbody td {
  padding: 1rem;
  color: #5e6871;
}

.cost-estimator-create-estimates-table__input {
  background: #ffffff;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;
}

.cost-estimator-create-estimates-table__input--sm {
  width: 50px;
}

.cost-esitmator-filter {
  margin: 20px 0 20px 0;
}

.cost-esitmator-filter label,
.cost-esitmator-filter button {
  margin-right: 0.625em;
  width: 15em;
}

.cost-estimator-create-calc {
  margin-top: 0.9375em;
  padding: 1em;
  border-top: 4px solid #f3f4f6;
  display: flex;
  flex-direction: column;
}

.cost-estimator-create-calc .float-left {
  float: left;
}

.cost-estimator-create-calc .float-right {
  float: right;
}

.cost-esitmator-create-dropdown {
  display: flex;
  flex: 1;
  align-items: center;
}

.cost-esitmator-create-dropdown .dropdown__container {
  flex: 1;
  margin-right: 0.625em;
}

.cost-esitmator-create-dropdown .dropdown__label {
  margin: 0px;
}

.cost-estimation-details table {
  border-collapse: separate;
  border-spacing: 14px;
  margin-left: auto;
}

.cost-estimation-details table th {
  font-size: 14px;
  font-weight: bold;
}

.cost-estimation-details table th:last-child {
  color: #41b375;
  font-size: 18px;
}

.cost-estimation-details table td {
  font-size: 12px;
  font-weight: bold;
}

.cost-estimation-details table td:last-child {
  color: #5e6871;
}

.cost-estimator-create-estimates-table table thead th.table__highlighted-cell,
.cost-estimator-create-estimates-table table tbody td.table__highlighted-cell {
  color: #41b375;
}

.cost-estimator-create-estimates-table table thead th.table__description-cell,
.cost-estimator-create-estimates-table table tbody td.table__description-cell {
  max-width: 240px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.patient-cost-estimator button[disabled] {
  background-color: #c6c7ca;
  color: #f3f4f6;
}

.verify-eligibility-btn {
  height: 35px !important;
}
