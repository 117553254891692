.code-card {
  margin-top: 12px;
}

.code-card__title {
  font-size: 14px;
  font-weight: 700;
}

.code-card__description {
  font-size: 14px;
  font-weight: 400;
}
