.tab-item {
  margin-bottom: 0;
  padding: 10px 20px;
  background: #fbfbfb;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: #aeb9c5;
  border-bottom-width: 0px !important;
}

.tab-item:hover {
  background: #f6f5f5;
}

.tab-item.active {
  background: #e5e9ed;
  font-weight: bold;
  border-bottom: 2px solid #ffffff;
  color: #000000;
}
