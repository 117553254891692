.search-with-option {
  display: flex;
  align-items: center;
}

.search-with-option__dropdown {
  margin-right: 12px;
  width: auto;
  min-width: 112px;
}
