.sop-popup {
  min-width: 1200px;
  max-height: 600px;
}

.sop-popup .appealio-popup--v3__title-wrapper {
  padding: 22px 48px;
}

.sop-popup .appealio-popup--v3__content {
  min-height: 200px;
  overflow-y: auto;
  border-top-right-radius: 0;
}

.sop-popup .appealio-popup--v3__content--scroll {
  overflow-y: auto;
}

.add-sop-form .ap-select-container {
  width: 214px;
}

.sop-steps-list {
  list-style: none;
  padding: 0;
  margin: 0;
  min-height: 200px;
  padding-bottom: 160px;
  height: 380px;
  overflow-y: auto;
  margin-right: -22px;
  padding-right: 22px;
}

.sop-steps-list--disabled .sop-steps-list__header {
  color: #aeb9c5;
  font-weight: 500;
}

.sop-steps-list li {
  padding: 16px;
  background-color: #e5e9ed;
  margin-bottom: 14px;
  border-radius: 16px;
}

.sop-steps-list li.sop-add-step-button-wrapper {
  background: none;
  padding: 0;
}

.alert-dialog.import-rule-practice-agent-permission.sop-delete-alert--popup {
  min-width: 700px;
}

.sop-submit-button-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  background: white;
  padding: 16px 32px;
  right: 0;
  border-bottom-right-radius: 16px;
  z-index: 1;
  border-top: 1px solid #e5e9ed;
}

.sop-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: -30px -48px;
  height: 450px;
  overflow: hidden;
  border-bottom-right-radius: 15px;
}

.sop-container__sidebar {
  width: 25%;
  background-color: #f3f5f7;
  padding: 22px;
  height: 100%;
  border-bottom-left-radius: 15px;
}

.sop-list-container {
  height: 380px;
  padding-bottom: 80px;
  overflow-y: auto;
  margin-right: -22px;
}

.sop-container__sidebar-title {
  font: normal normal 700 18px / normal 'Inter', sans-serif;
  color: #000;
}

.sop-container__main {
  width: 75%;
  padding: 22px;
  height: 100%;
  position: relative;
}

.sop-form-stepper {
  padding-right: 22px;
  padding-left: 22px;
  padding-bottom: 16px;
  border-bottom: 2px solid #e5e9ed;
  margin-left: -22px;
  margin-right: -22px;
  margin-bottom: 16px;
}

.add-update-sop-form__title {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.sop-form .toggle-wrapper {
  justify-content: flex-start !important;
}

.sop-form .toggle-wrapper .toggle {
  margin-left: 0 !important;
}

.sop-list-container .card-list__item {
  margin-right: 22px;
  line-height: 14px;
  text-transform: capitalize;
}

.add-update-sop-container {
  padding-bottom: 100px;
}

.sop-accordion-wrapper {
  margin: -32px -22px;
  position: absolute;
  z-index: 2;
  right: 22px;
  left: 22px;
  top: 22px;
}
.sop-accordion {
  margin: 10px 0;
  width: 100%;
  background-color: #e5e9ed;
}

.sop-accordion--open {
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
}

.sop-accordion__header {
  padding: 14px 22px;
  cursor: default;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 66px;
}

.sop-accordion__header h3 {
  margin: 0;
}

.sop-accordion__header-info-text {
  font-weight: 700;
  font-size: 10px;
  margin-right: 44px;
  width: 102px;
}

.sop-accordion__content {
  padding: 14px 22px;
}

.sop-accordion__content p {
  margin: 0;
}

.sop-accordion__content .toggle-wrapper--off .toggle {
  background-color: #1c72f2;
}

.add-update-sop-steps-wrapper {
  margin-top: 40px;
}

.sop-accordion__header-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sop-accordion__header-info-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sop-accordion-header-details__item {
  flex: 1;
  min-width: 130px;
}

.sop-accordion-header-details__item-title {
  color: #5e6871;
  font-size: 10px;
  font-weight: 700;
}

.sop-accordion-header-details__item-details {
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

.sop-steps-select__control.sop-steps-select__control--is-disabled,
.sop-steps-textarea .ap-input--disabled {
  background-color: #f3f5f7;
}

.sop-steps-select__single-value.sop-steps-select__single-value--is-disabled {
  color: #000;
}

.sop-steps-select__control--is-disabled
  .sop-steps-select__indicator.sop-steps-select__dropdown-indicator {
  visibility: hidden;
}

.ap-select.sop-steps-select--is-disabled.css-3iigni-container {
  pointer-events: visible;
}

.sop-steps-select__control.sop-steps-select__control--is-disabled
  .css-16xfy0z-con:hover {
  border-color: hsl(0, 0%, 90%);
}

.sop-steps-textarea textarea {
  padding: 4px 8px;
}
