.uhc-claim-status-check {
  min-width: 500px;
}

.uhc-claim-status-check__error {
  text-align: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.fetching-claim-status-message {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.claim-status-detail-popup .appealio-popup-title {
  display: none;
}

.uhc-claim-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 580px;
}

.uhc-claim-message__title {
  font-weight: 700;
  margin-bottom: 10px;
}

.dimmer:has(.claim-status-detail-popup) {
  z-index: 1004;
}

.uhc-claim-status-check-success-popup {
  min-width: 1000px;
  padding: 32px;
  overflow: hidden;
  width: auto;
  max-width: 1090px;
  max-height: 90vh;
}

.uhc-claim-status-check-success-popup .appealio-popup-content {
  padding: 0;
}

.uhc-claim-status-check-close-btn-container {
  display: flex;
  justify-content: flex-end;
}

.uhc-claim-status-check-close-btn-container button {
  width: 160px;
  height: 50px;
  justify-content: center;
  border-radius: 10px;
}

.claim-status-tracker-action-container {
  border-top: 1px solid black;
  margin-top: 20px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  min-height: 105px;
}

.claim-status-tracker-action-container form {
  width: 100%;
}

.claim-status-tracker-action-container
  .claim-status-tracker-action-container__title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #5e6871;
  margin: 20px 0;
}

.claim-status-tracker-action-container .add-claim-status-tracker {
  padding: 24px;
  height: 50px;
  border: 1px solid #0b283f;
  border-radius: 10px;
}

.claim-status-tracker__schedule-form {
  padding: 16px;
  background: #1f313d;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.claim-status-tracker__schedule-form .ap-select {
  border-radius: 4px;
  width: 140px;
}

.claim-status-tracker__schedule-form .ap-select-label,
.claim-status-tracker__schedule-form .ap-input-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 0;
  margin-right: 8px;
}

.claim-status-tracker__schedule-times {
  display: flex;
}

.claim-status-tracker__schedule-times .ap-input-label {
  margin-left: 8px;
}

.claim-status-tracker__schedule-days {
  margin-left: 8px;
}

.claim-status-tracker__schedule-days .ap-select-container {
  flex-direction: row;
  align-items: center;
}

.claim-status-tracker__schedule-times .ap-input-container {
  align-items: center;
  flex-direction: row-reverse;
  margin-left: 20px;
}

.claim-status-tracker__schedule-times .ap-input {
  padding: 10px;
  height: 38px;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 60px;
}

.claim-status-tracker__schedule-action {
  display: flex;
  align-items: center;
}

.claim-status-tracker__schedule-action button {
  justify-content: center;
  width: auto;
  margin-left: 20px;
  border: 0;
  height: 40px;
  min-width: 84px;
}

.claim-status-tracker__schedule-action .cancel-btn {
  background: #5e6871;
  color: #ffffff;
}

.schedule-info__days_select .ap-select-container {
  width: 150px;
}

.schedule-info__days_select .ap-select-label {
  display: none;
}
