.batch-submission-claim-table .submit-button-cell {
  width: 160px;
  text-align: center;
}

.batch-submission-claim-table .submit-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.batch-submission-claim-table .submit-button.visible {
  opacity: 1;
}

.batch-submission-claim-table tr:hover .submit-button {
  opacity: 1;
}

.submit-button {
  width: 140px;
  font-size: 12px;
  justify-content: center;
}
.batch-submission-claim-table {
  width: 100%;
}

.batch-submission-claim-table th {
  font-weight: 350;
  font-size: 12px;
  color: #1f313d;
  border-bottom: 1px solid #e5e9ed;
  padding: 2px 8px;
  font-weight: 400;
}

.batch-submission-claim-table td {
  font-size: 14px;
  color: #1f313d;
  font-weight: 350;
  padding: 4px 8px;
  word-wrap: break-word;
  border-bottom: 1px solid #e5e9ed;
}

.batch-submission-claim-table tbody tr {
  transition: background-color 0.3s;
}

.batch-submission-claim-table tbody tr:hover {
  background-color: #1c72f217;
}

.batch-submission-claim-table .selected {
  background-color: #e8f6ee;
}

.batch-submission-claim-table__row .submit-button {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.batch-submission-claim-table__row:hover .submit-button {
  opacity: 1;
}

.batch-submission-claim-table__row--success {
  background-color: #e8f6ee;
}

.batch-submission-claim-table__row--error {
  background-color: #f5d3d3;
}

.batch-submission-claim-table__col-patient {
  width: 110px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.batch-submission-claim-table__col-attachments {
  max-width: 150px;
}

.batch-submission-claim-table__col-claim-id {
  width: 110px;
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.batch-submission-claim-table__col-amounts {
  width: 100px;
  max-width: 100px;
}

.batch-submission-claim-table__col-amounts div {
  width: 100px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.batch-submission-claim-table__col-forms {
  width: 200px !important;
}

.batch-submission-claim-table__col-practice {
  width: 150px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.batch-submission-claim-table__col-practice div {
  width: 80px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.batch-submission-claim-table__col-service-date {
  width: 100px;
  max-width: 100px;
}

.batch-submission-claim-table__col-amounts {
  width: 120px;
  max-width: 120px;
}

.batch-submission-claim-table__col-amounts div {
  width: 120px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.batch-submission-claim-table__select-button {
  border: none;
  background-color: transparent;
}

.batch-submission-step2 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.batch-submission-step2__content {
  flex-grow: 1;
  overflow-y: auto;
}

.batch-submission-step2__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
}

.batch-submission-step2__footer .back-button {
  background-color: #ffffff;
  color: #333333;
  border: 1px solid #cccccc;
}

.batch-submission-step2__footer .footer-right {
  display: flex;
  align-items: center;
}

.batch-submission-step2__footer .selected-count {
  margin-right: 16px;
  font-size: 14px;
  color: #666666;
}

.batch-submission-step2__footer .submit-all-button {
  background-color: #007bff;
  color: #ffffff;
}

.batch-submission-step2__footer .submit-all-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.batch-submission-form-dropdown .dd-label {
  margin: 0px;
}

.batch-submission__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.create-in-progress-button {
  background-color: transparent;
}

.claim-created-button {
  background-color: #41b275;
  color: white;
  width: 100%;
}

.claim-error-button {
  background-color: #da4052;
  color: white;
  width: 100%;
}

.batch-submission-claim-table tbody tr:hover .dd-list__wrapper tr:hover {
  background-color: transparent;
}

.batch-submisison-claim-table tbody .dd-list__wrapper td {
  padding: 0 !important;
}

.batch-submission-claim-table__row--drag-over {
  background-color: #f3f5f7;
}
