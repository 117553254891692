.claims-csv-review {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.claims-csv-review-tabs {
  border-bottom: 1px solid #aeb9c5;
}

.claims-csv-review-tab {
  background: transparent !important;
  color: black !important;
  padding: 4px 15px !important;
  font-size: 14px !important;
  font-weight: normal;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.claims-csv-review-tab:hover {
  color: #1c72f2 !important;
  background-color: transparent;
}

.claims-csv-review-tab.claims-csv-review-tab--error {
  color: #f64d31 !important;
}

.claims-csv-review-tab.claims-csv-review-tab--error.active {
  color: #f64d31 !important;
  border-bottom: 4px solid #f64d31 !important;
}

.claims-csv-review-tab.active {
  font-weight: 500;
  color: #1c72f2;
  border-bottom: 4px solid #1c72f2 !important;
}

.claims-csv-review-tab--sub-tab {
  font-size: 12px !important;
}

.claims-csv-review-sub-tabs {
  margin-top: 4px !important;
}

.claims-csv-review-table-container {
  min-height: 440px;
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 440px;
  overflow: auto;
}

.claims-csv-review-table table {
  width: 100%;
  font-size: 12px;
  font-weight: normal;
}

.error-cell {
  color: #f64d31;
  cursor: pointer;
}
.claims-csv-review-table thead {
  background-color: #f3f5f7;
  position: sticky;
  top: 0;
  z-index: 1;
}

.claims-csv-review-table thead th {
  padding: 12px;
  text-align: left;
  font-weight: normal;
  position: sticky;
  top: 0;
  z-index: 1;
}

.claims-csv-review-table thead th:first-child {
  border-top-left-radius: 10px;
}

.claims-csv-review-table thead th:last-child {
  border-top-right-radius: 10px;
}

.claims-csv-review-table tbody tr:last-child {
  border-top-right-radius: 10px;
}

.claims-csv-review-table tbody tr {
  background-color: #fff;
}

.claims-csv-review-table tbody td {
  padding: 10px 12px;
  max-width: 128px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.claims-csv-review-tab--all {
  padding-bottom: 8px !important;
  font-size: 14px !important;
}

.claims-csv-review-tab--all:hover {
  color: black !important;
}

.claims-csv-review-sub-tab--all {
  padding-bottom: 4px !important;
  font-size: 12px !important;
}

.claims-csv-review-tab--error-message {
  position: absolute;
  right: 32px;
  font-size: 12px;
}
