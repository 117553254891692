.sop-popup-container {
  min-width: 960px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.sop-popup-container-title {
  font-size: 12px;
}

.sop-popup-container .appealio-popup-content {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sop-content-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  min-height: calc(20vh - 100px);
  height: calc(60vh - 100px);
  max-height: calc(80vh - 100px);
  overflow: hidden;
}

.sop-sidebar {
  width: 25%;
  background-color: #f3f5f7;
  padding: 22px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sop-sidebar h3 {
  margin-top: 0;
}

.sop-list {
  overflow-y: auto;
  flex-grow: 1;
}

.sop-main-content {
  width: 75%;
  height: 100%;
  overflow: hidden;
  padding: 22px;
  display: flex;
  flex-direction: column;
}

.sop-info-table-container {
  overflow-y: auto;
  flex-grow: 1;
}

.sop-info-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.sop-info-table thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}

.sop-info-table th {
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
}

.sop-info-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.sop-list-item {
  background: #fff;
  border-radius: 10px;
  color: #1f313d;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: 12px;
  max-width: 320px;
  padding: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sop-list-item:hover {
  background: #dbe8fb;
}

.sop-info-sn {
  width: 10px;
  text-align: center;
  max-width: 60px;
}

.sop-info-action {
  max-width: 180px;
  white-space: normal;
}

.sop-info-instruction {
}
.sop-list-item.selected {
  background: #dbe8fb;
}

.sop-info-instruction {
  width: 200px;
  white-space: pre-wrap;
  word-break: break-word !important;
}

.sop-info-table th {
  /* background-color: #f3f5f7; */
  font-size: 12px !important;
}

.sop-info-table thead:hover {
  color: #000000 !important;
  background: white !important;
}
