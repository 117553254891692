.download-csv-wrapper {
  display: inline;
}

.download-csv-wrapper .loading-indicator {
  left: -10%;
  top: 20px;
}

.ap-button--download {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  background-color: #ffffff;
  color: #7a8da4;
  height: 41px;
  min-width: 80px;
}

.ap-button--download:hover {
  background-color: #e5e9ed;
}

.ap-button--disabled:hover {
  opacity: 0.6;
  background: #d0d9e2;
}
