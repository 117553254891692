.site-under-maintenance {
  height: 100vh;
  background-color: #f3f5f7;
  display: flex;
  flex-direction: column;
}

.site-under-maintenance__main {
  margin: 0 auto;
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  text-align: center;
}

.site-under-maintenance__image {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 60vh;
  margin-bottom: 20px;
}

.site-under-maintenance__content {
  max-width: 594px;
  width: 100%;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.site-under-maintenance__content h1 {
  font: normal 700 34px / normal Inter;
}

.site-under-maintenance__content p {
  font: normal 400 18px/24px Inter;
  max-width: 469px;
}
