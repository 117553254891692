.in-progress-submissions-wrapper {
  width: 1400px;
  padding-top: 0;
  position: relative;
  min-height: calc(100vh - 168px);
  margin-left: auto;
  margin-right: auto;
}

.in-progress-submissions-wrapper .appealio-table {
  max-width: 1400px;
}

.in-progress-submissions-actions-wrapper {
  position: absolute;
  z-index: 1;
  top: -60px;
}
.in-progress-submissions-wrapper .action_popup {
  max-width: 800px;
}

.in-progress-submissions-wrapper .action_popup__title {
  min-width: 600px;
}

.submission-type-inprogress-counter {
  margin-top: 2px;
}
