.global-search-v2-container {
  position: absolute;
  width: 530px;
  top: 6px;
  right: 6px;
  z-index: 101;
}

.global-search-v2__input-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 530px;
  border: 1px solid #1c72f2;
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.global-search-v2__input {
  flex: 1;
  border: none;
  outline: none;
  font: 500 14px / normal Inter;
  color: #5e6871;
  padding: 0;
}

.global-search-v2__input-icon {
  margin-right: 8px;
  color: #aeb9c5;
  font-size: 18px;
}

.global-search-v2__search-result {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f3f5f7;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  padding: 16px;
  margin-top: 8px;
  min-height: 50px;
}

.global-search-v2__search-result--results {
  width: 145%;
}

.global-search-v2__search-result-content {
  min-height: 120px;
}

.global-search-v2__last-search-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.global-search-v2__last-search-item {
  margin-bottom: 10px;
}

.global-search-v2__last-search-item-title {
  font: 500 12px / normal Inter;
  color: #aeb9c5;
  margin-bottom: 8px;
}

.global-search-v2__last-search-item-content {
  font: 400 14px / normal Inter;
  color: #000000;
}

.global-search-v2__last-search-item-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.global-search-v2__last-search-item-list-item {
  margin-bottom: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
  line-height: 20px;
}

.global-search-v2__search-result-tabs {
  display: flex;
  align-items: center;
  margin: 6px 0;
  border-bottom: 1px solid #aeb9c5;
}

.global-search-v2__search-result-tab {
  color: #1f313d;
  font: normal 500 14px/19px Inter;
  margin-right: 20px;
  cursor: pointer;
}

.global-search-v2__search-result-tab--active {
  color: #1c72f2;
  border-bottom: 2px solid #1c72f2;
}

.global-search-v2__search-result-tab--disabled {
  color: #5e6871;
  cursor: not-allowed;
}

.global-search-v2__search-redirection {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  align-items: center;
  color: #000;
  font: normal 500 12px/19px Inter;
  background: rgba(0, 0, 0, 0.04);
  margin: 0 -16px -16px;
  cursor: pointer;
}

.global-search-v2__search-redirection:hover {
  color: #1c72f2;
}

.global-search-v2__search-redirection-icon {
  width: 24px;
  height: 24px;
  color: #5e6871;
}

.global-search-v2__show-all-results {
  color: #1c72f2;
  font: normal 500 14px/19px Inter;
  cursor: pointer;
  margin: 8px 0;
}

.global-search-v2__search-result-table-empty-state {
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-top: 40px;
}

.global-search-v2__search-result-table-empty-state .emptytable {
  background-color: transparent;
  font-size: 16px;
}

.global-search-v2__search-result-table__data-td--action {
  display: flex;
  align-items: center;
  visibility: hidden;
  margin-left: 6px;
}

.global-search-v2__search-result-table__data-row:hover
  .global-search-v2__search-result-table__data-td--action {
  visibility: visible;
}

.global-search-v2__search-result-table__data-td--action .ap-button--secondary {
  font-size: 12px;
  margin-right: 4px;
  border-radius: 4px;
  padding: 4px 6px;
}

.global-search-v2__search-result-table__data-td--action-btn {
  background-color: #f3f5f7;
  border-radius: 4px;
  outline: 0;
  border-color: #e5e9ed;
  cursor: pointer;
  border-style: inherit;
  height: 25px;
  width: 25px;
  padding: 0;
}

.global-search-v2__search-result-table__data-td--add-dq-btn {
  background-color: #0b283f;
  border-radius: 4px;
  outline: 0;
  border-color: #e5e9ed;
  cursor: pointer;
  border-style: inherit;
  height: 25px;
  width: 25px;
  padding: 0;
}
