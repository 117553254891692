.system-overview-actions-completed {
  background-color: #f3f5f7;
  height: 100%;
  padding: 2px;
  min-width: 64%;
  flex-grow: 1;
}

.system-overview-actions-completed__icon {
  height: 12px;
}

.system-overview-actions-completed__label {
  font-size: 12px;
  font-weight: 500;
  color: #1f313d;
}
