.ap-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background-color: #55a8fd;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #d0d9e2;

  font-weight: normal;
  font-size: 14px; /* Change/add condition later */
}

.ap-button--light {
  background: rgba(255, 255, 255, 0.0001);
  color: #1f313d;
}

.ap-button__title {
  display: flex;
  align-items: center;
}

.ap-button--primary-dark {
  background-color: #1c72f2;
  border-color: #1c72f2;
}

.ap-button--primary-red {
  background-color: #da4052 !important;
}

.ap-button--primary-red:hover {
  background-color: #b11b2d !important;
  color: white !important;
}

.ap-button--primary-md {
  background: #1c72f2;
  color: #ffffff;
  box-shadow: none;
  border-radius: 4px;
  font-size: 12px;
  border: 1px solid #1c72f2;
  padding: 8px 16px;
}

.ap-button--primary-md:hover {
  background: #ffffff;
  border: 1px solid #1c72f2;
  color: #1c72f2;
}

.ap-button--secondary {
  background-color: #0b283f;
}

.ap-button--secondary-outline {
  background: #ffffff;
  border: 1px solid #1f313d;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1f313d;
}

.ap-button--action {
  background: linear-gradient(180deg, #fafafa 0%, #ffffff 27.6%, #f0f0f0 100%);
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 30px;
  color: #5e6871;
  text-align: center;
  justify-content: center;
}

.ap-button--action:hover {
  color: #0b283f;
}

.ap-button--tertiary {
  background: #aeb9c5;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  color: #000000;
  text-align: center;
  min-width: 100px;
  justify-content: center;
}

.ap-button--action-delete:hover {
  color: #f64d31;
}
.ap-button--action--disabled {
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 30px;
  color: #5e6871;
  text-align: center;
  justify-content: center;
  opacity: 0.6;
  background: #d0d9e2;
}

.ap-button--disabled {
  opacity: 0.6;
  background: #d0d9e2;
}

.ap-button--link {
  background-color: transparent;
  font-size: 14px;
  color: #1c72f2;
  border: 0;
}

.ap-button--link:hover,
.ap-button--link:focus {
  outline: none;
}

.ap-button--link-danger {
  color: #f64d31;
}

.ap-button:disabled {
  opacity: 0.6;
  background: #d0d9e2;
}

.ap-button--primary-outline {
  background: #ffffff;
  border: 1px solid #1c72f2;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #000000;
  font-size: 12px;
  padding: 8px 18px 8px 12px;
  min-height: 34px;
  cursor: pointer;
}

.ap-button--primary-outline:hover {
  background-color: #1c72f2;
  color: #ffffff;
}

.ap-button--secondary-outline {
  background: #ffffff;
  border: 1px solid #1f313d;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1f313d;
}

.ap-button--rounded {
  border-radius: 16px;
  border: none;
}
.ap-button__icon {
  background-color: transparent;
  padding: 0;
  border: 0;
}

.ap-button__icon--disabled {
  cursor: not-allowed;
}

.ap-button--filter {
  border: 0;
  background-color: transparent;
  color: #000000;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 12px;
}

.ap-button--filter:hover,
.ap-button--filter-active {
  background-color: #25cf73;
  color: #ffffff;
}

.ap-button--filter:not(.ap-button--filter-active):disabled:hover {
  background-color: #ffffff;
  color: #000000;
}

.ap-button--block {
  width: 100%;
}

.ap-button--tertiary-outline {
  background: rgba(255, 255, 255, 0.0001);
  border: 1px solid #d0d9e2;
  border-radius: 4px;
  color: #5e6871;
  font-weight: 400;
  font-size: 14px;
}

.ap-button--floating {
  position: fixed;
  top: 180px;
  right: -178px;
  cursor: pointer;
  width: 238px;
  height: 50px;
  border: 2px solid #1c72f2;
  filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.15));
  border-radius: 5px;
  background: transparent;
  transition: 0.3s;
}

.ap-button--floating-hover:hover {
  right: -3px;
  color: #1d72f2;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
}

.ap-button--floating-hover span {
  margin-left: 6px;
}

.ap-button--floating .ap-button-loader {
  position: relative;
  margin-right: 30px;
}

.ap-button--floating .ap-button-loader .loading-indicator {
  position: absolute;
  right: 8px;
  height: 28px;
  margin-top: -14px;
}

.ap-button--floating
  .ap-button-loader
  .loading-indicator
  .loading-indicator__animation {
  border: 0.25rem solid #1c72f2;
  border-top-color: #fff;
}
