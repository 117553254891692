.checkbox-option-container[disabled] {
  cursor: not-allowed;
}

.checkbox-option-container .checkbox-option__input {
  margin: 0 8px;
}

.checkbox-option-container .checkbox-option__label {
  margin: 0;
  color: #5e6871;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 16px;
  background-color: #f3f5f7;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid #e5e9ed;
  text-transform: capitalize;
  display: inline-flex;
  align-items: center;
  color: #5e6871;
  outline: none;
  cursor: pointer;
}

/* Disabled state */
.checkbox-option-container .checkbox-option__label[disabled] {
  background-color: grey;
  color: grey;
  border: 1px solid grey;
  cursor: not-allowed;
  pointer-events: none;
}

.checkbox-option-container .checkbox-option__label[disabled]:before {
  background-color: transparent !important;
}

/* Active state */
.checkbox-option-container .checkbox-option__label--active {
  background-color: rgba(28, 114, 242, 0.09);
}

.checkbox-option-container .checkbox-option__label--without-background {
  background: none;
  border: none;
  padding: 0;
}
