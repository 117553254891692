.settings-content-wrapper-fee-schedules .col-lg-3 {
  width: 20.4%;
}

.settings-content-wrapper-fee-schedules .input-search-box,
.settings-content-wrapper-fee-schedules .input-search-box input {
  border-radius: 4px;
}

.settings-content-wrapper-fee-schedules .input-search-box {
  display: flex;
  align-items: center;
}

.add-fee-schedule-form .SingleDatePickerInput {
  display: flex;
  width: 170px;
}

.add-fee-schedule-form .ap-select.ap-select-label {
  display: none;
}

.fee-schedule-select {
  width: 140px;
}

.fee-schedule-select .dd-value-container {
  padding: 5px 8px;
}

.add-fee-schedule-form .ap-select .fee-schedule-select__control,
.add-fee-schedule-form .ap-select .fee-schedule-select__value-container {
  min-height: 32px;
  height: 32px;
  width: 140px;
}

.add-fee-schedule-form
  .ap-select
  .fee-schedule-select__control
  .fee-schedule-select__indicator {
  padding: 4px;
}

.add-fee-schedule-form .fee-schedule-select__placeholder {
  font-size: 12px;
}

.add-fee-schedule-form .ap-input {
  padding: 5px 10px;
  width: 100%;
}

.add-fee-schedule-form .SingleDatePicker .DateInput_input {
  padding: 2px 11px 2px;
}

.add-fee-schedule-form .fee-schedule-table__info-icon {
  position: absolute;
  right: 0%;
  top: 8px;
  cursor: pointer;
  color: #0b283f;
}
