.action-bar-wrapper {
  width: 100%;
  display: flex;
  gap: 4px;
  padding: 2px 0;
}

.action-bar-wrapper--small {
  gap: 0px;
}

.action-bar-wrapper--lg {
  padding: 6px 0;
}

.action-bar {
  height: 100%;
  border-radius: 4px;
  background: #cccccc;
}

.action-bar-label {
  font-size: 12px;
  margin-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: flex;
  align-items: center;
}

.action-bar-wrapper--lg .action-bar-label {
  font-size: 14px;
  padding: 6px 0;
}

.action-bar-label--small {
  text-overflow: clip;
}

.action-bar-legends-wrapper {
  display: flex;
  justify-content: flex-end;
}

.action-bar-legend {
  display: flex;
  align-items: center;
  margin-right: 12px;
}

.action-bar-legend-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px;
  background-color: blue;
}

.action-bar-tooltip-title {
  font-size: 12px;
}
