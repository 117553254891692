.dimmer:has(.claim-status-detail-popup) {
  z-index: 1004;
}

.availity-claim-status-check-form {
  min-width: 1000px;
  border-radius: 8px;
}

.availity-claim-status-check-form .appealio-popup-title {
  background-color: white;
  color: black;
  border-bottom: 1px solid #d0d9e2;
}
.availity-claim-status-check-form .appealio-popup-close {
  filter: invert(1);
}
.submit-button {
  height: 40px;
  min-width: 90px;
  display: flex;
  justify-content: center;
}
