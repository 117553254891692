.notes-popup {
  max-height: 700px;
  overflow: hidden;
  min-height: 620px;
}

.notes-popup .ap-popup__footer {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.notes-popup .ap-popup__content {
  background-color: #fbfbfb;
  overflow-y: auto;
  max-height: 600px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 500px;
  padding-bottom: 140px;
}

.note-list {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

.note-list__item {
  border-bottom: 1px solid #d0d9e2;
  padding: 12px 0;
}

.note-list__item-info {
  font: normal normal 400 12px/15px 'Inter', sans-serif;
  color: #5e6871;
  margin-bottom: 4px;
}

.note-list__item-info span {
  margin-right: 8px;
}

.note-list__item-note {
  font: normal normal 400 14px/19px 'Inter', sans-serif;
  color: #1f313d;
  margin-bottom: 0;
  white-space: pre-wrap;
  word-break: break-all;
}

.input-note-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #d0d9e2;
  border-radius: 10px;
}

.input-note-wrapper .input-note {
  border: 0;
  padding: 10px;
  border-radius: 10px;
  resize: none;
  width: 100%;
}

.input-note-wrapper button {
  height: 100%;
  border-radius: 10px;
  padding: 10px;
}

.no-notes-wrapper {
  margin-top: 100px;
}

.no-notes-mage {
  width: 150px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.no-notes-label {
  text-align: center;
  color: #011d2e;
  font-weight: 500;
  line-height: 1.3;
  margin: 40px auto 0;
}
