.ap-popup-list__list {
  padding: 0;
  list-style: none;
}

.ap-popup-list__list--item {
  background-color: #e5e9ed;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.ap-popup-list__list--item input[type='checkbox'] {
  margin-top: 0;
  margin-right: 8px;
}

.ap-popup-list__list--item:nth-child(even) {
  background-color: #ffffff;
}

.ap-popup-list__sticky-search-bar {
  position: sticky;
  top: -32px;
  background-color: #fff;
  z-index: 1;
  padding: 10px 0;
}

.empty-teams-popup {
  .appealio-popup--v3__content {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 90px;
  }
}
