.dd-search-box {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 4px 8px;
  width: 100%;
  height: 41px;
}

.dd-search-icon {
  margin-right: 10px;
}

.dd-search-box input {
  border: none;
  outline: none;
  width: 100%;
}

.dd-search-clear {
  cursor: pointer;
}
