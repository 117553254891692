.secondary-nav {
  display: flex;
  padding-left: 0;
  list-style: none;
  padding-bottom: 8px;
  border-bottom: 1px solid #aeb9c5;
  overflow: auto;
}

.secondary-nav__item {
  margin-right: 24px;
  white-space: nowrap;
}

.secondary-nav__link {
  font: normal normal 500 16px/19px 'Inter', sans-serif;
  color: #5e6871;
}

.secondary-nav__link--primary.active {
  font-weight: 700;
  color: #002841;
  padding-bottom: 6px;
  border-bottom: 3px solid #002841;
}

.secondary-nav__link:hover,
.secondary-nav__link:focus {
  text-decoration: none;
  color: #002841;
}
