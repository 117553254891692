.appealio-table-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
}

.appealio-table {
  background: #ffffff;
  min-height: calc(100% - 190px);
  max-width: 1310px;
  width: 100%;
  padding-top: 28px;
  margin: 0 auto;
  table-layout: fixed;
  margin-bottom: 40px;
}

.appealio-table__action-bar {
  padding: 12px;
  background: #e5e9ed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  position: relative;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.appealio-table__filter {
  display: flex;
  align-items: center;
}

.appealio-table__filter .dropdown__menu {
  z-index: 9;
}

.appealio-table__action-bar .search-box {
  position: absolute;
  right: 8px;
  z-index: 9;
}

.appealio-filter-button {
  height: 42px;
}

.appealio-table-container .appealio-table__checkbox-action {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.appealio-table__action-bar-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.appealio-table--default-width {
  width: 100%;
  max-width: 100%;
}

.appealio-table .dashboard-bottom-loader {
  margin: 0px;
  padding: 20px 0;
}

.appealio-table thead th {
  font-size: 14px;
  line-height: 19px;
  color: #1f313d;
  font-weight: normal;
  padding: 14px;
  border-bottom: 2px solid #d0d9e2;
  cursor: pointer;
}

.appealio-table thead th:hover {
  color: #000000;
}

.appealio-table__body-row:hover {
  background-color: #f3f5f7;
}

.appealio-table tbody td {
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #1f313d;
}

.appealio-table-cell__border--right {
  border-right: 1px solid #aeb9c5;
}

.appealio-table-cell__border--left {
  border-left: 1px solid #f3f5f7;
}

.appealio-table-cell__break-word {
  word-break: break-word;
}

.appealio-table .table-status__cell {
  display: flex;
  align-items: center;
}

.appealio-table .table-status__cell .pill {
  width: 84px;
}

.appealio-table .table-status__cell .pill--xl {
  width: 120px;
}

.status-tracker-table .table-status__cell .pill,
.claim-history-flyover__hisory-table .pill {
  min-width: 84px;
  max-width: 120px;
  width: 100%;
}

.appealio-table .table-status__cell--action {
  padding: 0 8px;
  display: flex;
  align-items: center;
}

.appealio-table.inprogress-submissions-table .table-status__cell--action {
  padding-left: 0;
}

.appealio-table.inprogress-submissions-table tbody tr {
  cursor: pointer;
}

.appealio-table thead th:first-child {
  padding-left: 16px;
}

.appealio-table tbody td:first-child {
  padding-left: 16px;
}

.appealio-table.appealio-table--record-appeal thead th:first-child {
  padding-left: 60px;
}

.appealio-table.appealio-table--default-width.denied-claims-table
  th:first-child {
  padding-left: 18px;
}

.appealio-table__custom-cell {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.appealio-table .appealio-table__custom-cell--visible {
  overflow: visible;
}

.appealio-table__custom-cell--is-link {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.appealio-table__body-col--sorted {
  background: hsla(0, 0%, 77%, 0.3);
}

.appealio-table__body-row--disabled {
  opacity: 0.4;
  cursor: pointer;
}

.appealio-table__body-row--highlighted {
  background-color: #cff9cf;
}

.table-span--fixed-width {
  width: 110px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.counter-wrapper-cell {
  margin-right: 12px;
  min-width: 20px;
  max-width: 40px;
}

.appealio-table-checkbox-action {
  position: relative;
}

.appealio-table-checkbox-action .mark-complete-btn,
.table-status__cell--action .delete-submission-button {
  position: absolute;
  left: 110px;
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  border: 1px solid #e5e9ed;
  border-radius: 15px;
  outline: 0;
  z-index: 1;
  display: none;
  width: 150px;
  padding: 4px;
  top: -2px;
}

.table-status__cell--action .delete-submission-button {
  left: 50px;
  top: 12px;
  max-width: 120px;
}

.appealio-table__body-row:hover
  .appealio-table-checkbox-action
  .mark-complete-btn,
.appealio-table__body-row:hover
  .table-status__cell--action
  .delete-submission-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.denials-queue-removal-logs--todo
  .appealio-table__body-row:hover
  .blur-on-td-hover {
  opacity: 0.4;
}

.appealio-table-body--grey-1-bg {
  background: #f3f5f7;
}

.table-status__cell--note {
  padding-left: 10px;
}
