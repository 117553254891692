.ap-input-container {
  display: flex;
  flex-direction: column;
}

.ap-input-label {
  font-size: 14px;
  color: #1f313d;
  font-weight: normal;
  display: inline-flex;
}

.ap-input {
  /* White */
  background: #ffffff;
  /* Grey 3 */

  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 4px;

  padding: 8px 12px;
  font-size: 14px;
}

.ap-input-error {
  font-size: 12px;
  color: red;
}

.ap-input--disabled {
  background-color: #f3f3f3;
  border: solid 2px #dedede;
}

.ap-input--error {
  border-color: red;
}
