.import-rule-popup {
  max-width: 1350px !important;
  width: 1350px !important;
  min-height: 80%;
  border-radius: 10px;
}

.import-rule-popup .appealio-popup-content {
  height: 80vh;
  overflow: auto;
}

.import-rule-popup .appealio-popup-title {
  background-color: white;
  color: black;
  border-bottom: 1px solid #d0d9e2;
}

.import-rule-popup .appealio-popup-close {
  filter: invert(1);
}

.table-header {
  padding: 16px;
  font-weight: 500;
  font-size: 14px;
  color: #5e6871;
}

.settings-content-wrapper .info-header__tooltip {
  width: 424px;
  color: #ffffff;
}

.gutter-padding-10 > [class*='col-'] {
  padding-right: 10px;
  padding-left: 10px;
}

.add-new-rule-button {
  padding: 16px;
  font-size: 12px;
  color: #000000;
  font-weight: 500;
  background: #f3f5f7;
  text-decoration: underline;
  position: fixed;
  top: 16px;
  right: 100px;
}

.rule-exclusion-display {
  font: normal italic 500 10px/19px 'Inter', sans-serif;
  color: #8e959f;
}
