.appealio-duplicate-claim-popup {
  max-width: 742px;
}

.appealio-duplicate-claim-popup .claim-info {
  display: inline-flex;
}

.appealio-duplicate-claim-popup .claim-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.appealio-duplicate-claim-popup .claim-options:last-child {
  margin-bottom: 0;
}

.duplicate-claim-popup__letter-pdf-form-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.duplicate-claim-popup__letter-pdf-form-option--link {
  text-decoration: underline;
  font-size: 14px;
  color: #1c72f2;
}

.appealio-resend-identical-claim-popup {
  overflow: hidden;
}

.appealio-resend-identical-claim-popup .appealio-popup--v3__content {
  overflow: auto;
  max-height: 70vh;
}

.preview_button--identical-appeal {
  background-color: initial;
  border: none;
  color: #7a8da4;
  outline: none;
  padding: 0;
  width: 30%;
}
