.counter {
  width: 16px;
  height: 16px;
  background-color: #c4c4c4;
  border-radius: 50%;
  color: #000000;
  font-weight: 700;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
