.floating-print-btn-wrapper {
  position: absolute;
  position: fixed;
  bottom: 80px;
  right: 80px;
  z-index: 999;
}
.floating-print-btn {
  background-color: aliceblue;
  padding: 20px;
  border: 1px solid rgb(208, 217, 226);
  border-radius: 50%;
  height: 64px;
  width: 64px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
}
.print-icon {
  font-size: 24px;
}
