.claim-accordion {
  background-color: #f3f5f7;
  transition: background-color 0.3s;
  cursor: pointer;
  margin-left: -32px;
  margin-right: -32px;
  padding: 12px 32px;
  position: relative;
  z-index: 1;
}

.claim-accordion:hover,
.claim-accordion:hover .claim-accordion__content {
  background-color: #e7ebef;
}

.claim-accordion--open {
  box-shadow: 0px 5px 10px 0px #0000001c;
}

.claim-accordion__header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.claim-accordion__info-text {
  font-size: 14px;
}

.claim-accordion__content {
  position: absolute;
  left: 0;
  right: 0;
  background-color: #f3f5f7;
  padding: 12px 32px;
  /* box-shadow: 0px 5px 10px 0px #0000001c; */
  z-index: 2;
  transition: background-color 0.3s;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.claim-accordion__table {
  margin-top: 12px;
  width: 100%;
}

.claim-accordion__table th {
  color: #1f313d;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
}

.claim-accordion__table td,
.claim-accordion__table td.claim-accordion__table-td--practice-provider div {
  font-size: 14px;
  text-align: left;
  color: #1f313d;
  padding: 4px 2px;
  max-width: 100px;
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.batch-submission-filter__search_string_filter {
  height: 40px;
}
