.ae-signup-page {
  background-color: #eff7ff;
  padding: 80px 0;
}

.ae-signup-page__container {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
}

.ae-signup-page__hero {
  width: 40%;
  margin-top: 70px;
}

.ae-signup-page__hero img {
  margin: 60px 0;
  display: block;
  width: 80%;
}

.ae-signup-page__title {
  font: normal normal 800 42px/52px 'Inter', sans-serif;
  color: #0b283f;
  text-align: center;
  margin-bottom: 16px;
}

.standalone-signup-form {
  width: 60%;
}

.signup-card {
  padding: 32px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 30px 40px rgb(212 217 232 / 20%);
  border: 0;
  border-radius: 10px;
  width: 80%;
}

.standalone-signup {
  display: block;
  position: relative;
  text-align: left;
  padding: 10px 0 10px 10px;
}

.standalone-signup form {
  text-align: left;
}

.standalone-signup-data {
  font-size: 12px;
}

.standalone-signup-data .appealio-action-popup {
  max-width: 900px;
}

.signup-form-data {
  width: 100%;
}

.signup-sub-header {
  text-align: left;
  font-weight: bold;
}

.signup-card-title {
  margin: 16px 20px;
}

.signupForm-submit-with-validation {
  background-color: #aaa;
  border: 0 none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  margin-left: 14px;
  width: 96%;
  margin-top: 20px;
}

.signupForm-submit-after-validation {
  background-color: #1c72f2;
  border: 0 none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  margin-left: 14px;
  width: 96%;
  margin-top: 20px;
}

.signupForm-submit-after-validation:disabled {
  background-color: #ccc;
}

.password-validation {
  padding-left: 20px;
}

/*
css for registration completed page
*/

.signup-completed-card {
  position: relative;
  top: 110px;
  width: 320px;
  display: block;
  margin: auto;
  text-align: center;
}

.upper-side {
  padding: 32px;
  background-color: #8bc34a;
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.middle-heading {
  margin-top: 20px;
}

/* payment Details  */
.payment-details-wrapper {
  padding-top: 40px;
  background-color: #eff7ff;
  position: relative;
  width: 100%;
  height: 100%;
}

.payment-details-card {
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  background: #ffffff;
  overflow: hidden;
  padding: 32px;
  position: relative;
}
.pricing-heading {
  text-align: left;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 40px;
}

.payment-details-line {
  border-left: 2px solid #83818145;
  height: calc(100% - 50px);
  position: absolute;
  left: 50%;
  margin-left: -3px;
  top: 26px;
  overflow: hidden;
}

.price-list {
  font-size: 14px;
}

.price-heading {
  padding-top: 0px;
  margin-top: 0px;
  color: #000000;
  font-weight: bold;
}

.pricing-sub-heading {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 16px;
}

.price-list-for-sub-cost {
  font-weight: bold;
  font-size: 24px;
  margin-right: 32px;
}

.pricing-sub-cost {
  margin-bottom: 0;
}

.stripe-button {
  background-color: #0069d9;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.stripe-button:hover {
  background-color: #0052a3;
}

.stripe-form-error {
  padding-top: 5px;
  color: red;
}

.stripe-form-success {
  padding-top: 5px;
  color: red;
}

.payment__header-logo {
  height: 70px;
  padding-left: 157px;
}

.payment-successful-message {
  padding-top: 20px;
  color: rgb(130, 122, 122);
  /* text-align: center; */
}

.payment-successful-message-v1 {
  padding-left: 120px;
  color: black;
  font-size: 20px;
}

.payment-successful-message-v2 {
  color: black;
  font-size: 18px;
  padding-left: 10px;
}

.delivery-pl {
  margin-left: 103px !important;
}

.payment-successful-card {
  margin-left: auto;
  margin-right: auto;
  height: 329px;
  width: 500px;
  background: #ffffff;
  overflow: hidden;
}

.payment-successful-wrapper {
  padding-left: 50px;
  padding-top: 48px;
}

.pricing-note {
  font-size: 14px;
  font-weight: 500;
  margin-top: 108px;
  padding: 8px;
  border-radius: 4px;
  background: #e4f1ff;
  border: 1px solid #d6efff;
}

/* Terms and conditions  */
.terms-and-conditions__main-header {
  max-height: 400px;
  overflow-y: scroll;
  font-size: 14px;
  overscroll-behavior: contain;
}

.terms-and-conditions__sub-header {
  text-align: center;
  padding: 20px;
}
.terms-and-conditions__sub-header-align {
  display: block;
  text-align: center;
  font-style: italic;
}

.terms_and_conditions_footer {
  font-weight: bold;
}

.terms-and-conditions-popup__content {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  max-width: 900px;
  overflow: visible;
  width: 100%;
}
