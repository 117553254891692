.successful-account-wrapper {
  min-height: 100vh;
  background-color: #e5e5e5;
  padding-top: 24px;
}
.successful-account-container {
  margin: 0 auto;
  width: 900px;
  background-color: white;
  padding: 52px 116px;
}

.successful-account-title {
  color: #000000;
  font-weight: bold;
  font-size: 34px;
  text-align: center;
}

.successful-account-subtitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.count-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
}

.practice-count-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  width: 136px;
  margin: 6px;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.practice-count-card__count {
  color: #5e6871;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-weight: 800;
  font-size: 88px;
  line-height: 106px;
}

.practice-count-card__title {
  font-weight: bold;
  font-size: 16px;
}

.action-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.action-button {
  width: 300px;
  justify-content: center;
  font-size: 14px;
  margin-top: 8px;
}
