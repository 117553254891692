.add-payer-contact-popup .appealio-popup--v3__content {
  overflow: scroll;
}

.add-edi-payerId {
  margin-top: 6px;
  padding-left: 15px;
  font-size: 12px;
}

.add-edi-payerId span {
  text-decoration: underline;
  color: #1c72f2;
  cursor: pointer;
  padding-left: 2px;
}

.payerIdentifier-popup--v3 {
  background-color: #ffffff;
  border-radius: 15px;
  overflow: unset;
  min-width: 500px;
}

.dimmer:has(.payerIdentifier-popup--v3) {
  min-height: 535px;
  border-radius: 10px;
}
