.search-result-container {
  padding: 12px 4px;
  margin-top: 4px;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #0b283f;
}

.search-list {
  padding-left: 10px;
}

.search-list-title {
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 700;
  font-style: italic;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.search-list-title:hover {
  background: #e5e9ed;
  border-radius: 20px;
}

.search-list-item {
  padding: 2px 4px;
  cursor: pointer;
  padding-left: 20px;
  transition: all 0.3s ease;
  border-radius: 25px;
}

.search-list-footer {
  display: inline;
  float: right;
  margin-top: 20px;
  padding-right: 16px;
  font-style: italic;
  border: 1px solid #e5e9ed;
}

.search-list-item:hover {
  background: #e5e9ed;
  padding: 6px auto 6px 20px;
  border-color: #0b283f;
}

.search-list-footer--hover {
  background: #e5e9ed;
}

.search-list-footer--hover:hover {
  border-color: #0b283f;
}

.search-list-item-title {
  font-size: 11px;
  font-weight: 700;
  color: #5e6871;
  display: none;
}

.search-list-item:hover .search-list-item-title {
  display: block;
}

.search-list-item-search-claim-btn {
  display: none !important;
  margin-top: 4px;
  width: 60px;
  padding: 0px;
}

.search-list-item:hover .search-list-item-search-claim-btn {
  display: flex !important;
}

.search-list-item-search-claim-btn-icon {
  height: 12px;
}
