.update-benefit-popup {
  max-width: 1306px !important;
  width: 632px !important;
  border-radius: 10px;
}

.update-benefit-popup .appealio-popup-title {
  background-color: white;
  color: black;
  border-bottom: 1px solid #d0d9e2;
}
