.import-rule-container {
  border: 1px solid #f3f5f7;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(79, 82, 85, 0.26);

  margin-bottom: 10px;
}

.table-body {
  padding: 12px;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0;
}

.rules-form {
  padding: 22px 32px;
  background: #f3f5f7;
}

.d-inline-with-padding {
  padding-left: 10px;
  display: inline-block;
}

.col-width-7 {
  width: 7.6%;
}

.col-width-10 {
  width: 10%;
}

.col-width-10 span {
  padding-left: 3px;
}

.col-width-15 {
  width: 15%;
}
.col-width-15 span {
  padding-left: 3px;
}

.col-table-body-width-7 {
  width: 7.6%;
  padding-left: 30px;
}

.business-rule-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rule-name-icon {
  width: 14px;
  height: 14px;
  vertical-align: top;
}

.card-view__table-row-item {
  font-weight: 500;
  font-size: 12px;
}

.card-view__table-header-th,
.card-view__table-row-item {
  padding: 0 6px;
}

.card-view__table-header-th:nth-child(1),
.card-view__table-row-item:nth-child(1) {
  flex: 0 0 9%; /* Status Column */
}

.card-view__table-header-th:nth-child(2),
.card-view__table-row-item:nth-child(2) {
  flex: 0 0 13%; /* Rule Column */
}

.card-view__table-header-th:nth-child(3),
.card-view__table-row-item:nth-child(3) {
  flex: 0 0 6%; /* SOP Column */
}
.card-view__table-header-th:nth-child(13),
.card-view__table-row-item:nth-child(13) {
  flex: 0 0 6%; /* Edit Column */
}

.sop-icon-button {
  display: flex;
  width: 42px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 17px;
  background: #e5e9ed;
}

.sop-icon-button--disabled {
  cursor: not-allowed;
}
