.appealio-card-v2 {
  background: #ffffff;
  border: 1px solid #d0d9e2;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 40px 48px;
  margin-bottom: 26px;
}

.appealio-card-v2__title {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.appealio-card-v2__title--heading {
  font-size: 22px;
}

.appealio-card-v2__description {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #5e6871;
  margin-bottom: 6px;
}

.appealio-card-v2 button {
  padding: 12px;
  width: 100%;
  justify-content: center;
}

.appealio-card-v2 .add-btn--main {
  width: auto;
  min-width: 160px;
  margin-left: auto;
}

.appealio-card-v2 .add-item-btn {
  text-align: left;
}

.navigation-button-wrapper button {
  width: 166px;
  padding: 12px 0;
  text-align: center;
  justify-content: center;
  margin-left: auto;
}

.navigation-button-wrapper button.disabled {
  background-color: #aeb9c5;
}
