.practice-group__practice-list {
  padding: 0;
  list-style: none;
}

.practice-group__practice-list--item {
  background-color: #e5e9ed;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.practice-group__practice-list--item input[type='checkbox'] {
  margin-top: 0;
  margin-right: 8px;
}

.practice-group__practice-list--item:nth-child(even) {
  background-color: #ffffff;
}

.practice-group__sticky-search-bar {
  position: sticky;
  top: -32px;
  background-color: #fff;
  z-index: 1;
  padding: 10px 0;
}

.empty-practice-popup {
  .appealio-popup--v3__content {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 100px;
  }
}

.practice-group-confirm-cancel-popup__title {
  font-size: 14px;
  font-weight: 500;
}
.practice-group-confirm-cancel-popup__item {
  font-size: 14px;
  margin-left: 4px;
}

.practice-group-dropdown {
  position: relative;
}

.practice-group-dropdown .dd-wrapper {
  z-index: 1000;
}
