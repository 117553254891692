.topbar__notification-ic {
  height: 30px;
  width: 35px;
}

.topbar__notification--count {
  background-color: #da4052;
  border-radius: 50%;
  font-size: 12px;
  height: 16px;
  position: absolute;
  right: 0px;
  top: 14px;
  text-align: center;
  width: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topbar__dropmenu--notification {
  cursor: pointer;
  float: right;
  margin-right: 16px;
  position: relative;
  margin-top: 16px;
}

.notification-container {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  min-width: 320px;
  max-width: 430px;
  position: absolute;
  right: 210px;
  top: 54px;
  z-index: 10;
  background-color: #ffffff;
}

.notification-header {
  background-color: #1f313d;
  color: #ffffff;
  padding: 10px 15px;
}

.notification-count {
  font-weight: bold;
  font-size: 14px;
}

.notification-time {
  font-size: 12px;
  color: #ffffff;
}

.notification-content {
  background-color: #ffffff;
}

.notification-item {
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 18px;
  justify-content: space-between;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #dbebfb;
}

.notification-item-unread {
  background-color: #f3f5f7;
}

.notification-dot {
  width: 8px;
  height: 8px;
  background-color: #e74c3c;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-text {
  flex-grow: 1;
  color: #000000;
  font-size: 12px;
}

.notification-empty {
  font-size: 12px;
  color: #000000;
  padding-top: 10px;
}

.notification-line {
  background-color: #f3f5f7;
  height: 1px;
  margin: 12px auto;
  width: 100%;
}
