.csv-claims-import-find-and-replace-popup {
  min-width: 800px !important;
}

.csv-claims-import-find-and-replace-popup__info {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
}

.csv-claims-import-find-and-replace-popup__content {
  min-height: 500px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.find-and-replace-row {
  display: flex;
  align-items: flex-end;
}

.find-and-replace-row__sn {
  width: 30px;
  font-weight: 400;
  margin-bottom: 8px;
}

.find-and-replace-row__row {
  width: 80px;
}

.find-and-replace-row__info {
  font-size: 12px;
  margin-top: 4px;
  font-weight: 400;
  margin-bottom: 16px;
  margin-left: 48px;
}
