.analytics-widget {
  border-radius: 15px;
  border: 1px solid #d0d9e2;
  background: #fff;
  padding: 32px;
  margin: 20px auto;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
}

.analytics-widget__title {
  margin: 0 0 26px 0;
  font: normal normal 500 24px/19px 'Inter', sans-serif;
  color: #000;
}

.analytics-widget__filter-tabs-wrapper {
  display: flex;
  align-items: center;
}

.analytics-widget__filter-tabs-title {
  font: normal normal 500 15px/19px 'Inter', sans-serif;
  color: #000;
  margin-right: 24px;
}

.analytics-widget__filter-tabs {
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.analytics-widget__filter-tabs-item {
  border-radius: 10px;
  border: 1px solid #f3f5f7;
  font: normal normal 500 15px/19px 'Inter', sans-serif;
  padding: 4px 16px;
  cursor: pointer;
  margin-right: 10px;
}

.analytics-widget__filter-tabs-item:hover {
  background: #e9e9e9;
}

.analytics-widget__filter-tabs-item--active {
  background: #e9e9e9;
}

.analytics-widget__filter-tabs-wrapper {
  margin-bottom: 16px;
}

.rounded-corner {
  border-radius: 4px;
  overflow: hidden;
}
