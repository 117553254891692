.rule-table-container {
  max-height: 600px;
  overflow-y: auto;
  min-height: 200px;
}

.rule-table {
  width: 100%;
  background: #ffffff;
  min-height: calc(100% - 190px);
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0 15px;
  position: relative;
}

.rule-table thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ffffff;
}

.rule-table__header-row,
.rule-table__body-row {
  display: flex;
  align-items: center;
}

.rule-table__header-col {
  cursor: pointer;
  color: #5e6871;
  padding: 8px;
  font: normal normal 500 12px/19px 'Inter', sans-serif;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.rule-table__body-row {
  border-radius: 10px;
  background: #fbfbfb;
  height: 68px;
  margin-bottom: 16px;
}

.rule-table__body-td {
  padding: 12px 8px;
}

.background-cell--grey {
  border-radius: 10px;
  background-color: rgba(11, 41, 65, 0.05);
  padding: 12px;
  color: #000;
}

.tooltip-details-background {
  background-color: #2c2f32f2 !important;
  min-width: 246px;
  opacity: 1 !important;
  pointer-events: none;
}

.tooltip-details {
  font-weight: 700;
  font-size: 10px;
}

.tooltip-details div {
  margin-bottom: 8px;
}

.tooltip-details__title {
  margin-bottom: 0;
}

.tooltip-details__line {
  margin: 6px 0;
}
