.codes-info-popup .appealio-popup-content {
  max-height: 80vh;
  overflow: auto;
}

.codes-info-popup {
  width: 740px;
  min-height: 240px;
  max-width: 815px;
}

.code-type-title {
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 2px;
  border-bottom: 1px solid #d0d9e2;
}
