.request-pm-note-template-popup {
  min-width: 700px;
}

.show-pm-note-template-popup {
  min-height: 80vh;
  max-height: 80vh;
}

.show-pm-note-template-popup .appeal-letter__preview--content {
  min-height: 600px;
  max-height: 600px;
}
