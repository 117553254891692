.navigation-toggle {
  display: flex;
  border: 1px solid #1f313d;
  border-radius: 6px;
  width: 200px;
  overflow: hidden;
}

.navigation-toggle-item {
  display: flex;
  align-items: center;
  padding: 5px 8px;
}

.navigation-toggle-item--active {
  background-color: #1f313d;
  color: #fff;
  width: 100%;

  svg {
    stroke: #fff;
  }
}
.navigation-toggle-item svg {
  display: block;
  width: 24px;
  height: 24px;
}
.navigation-toggle-item--active svg rect,
.navigation-toggle-item--active svg path {
  stroke: #fff;
}

.navigation-toggle-item-label {
  width: 0px;
  height: 0px;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  white-space: nowrap;
}

.navigation-toggle-item-label--visible {
  width: 100%;
  height: auto;
}
