.reminder-icon {
  width: 24px;
  height: auto;
}

.disabled-reminder-icon {
  pointer-events: none;
}

.reminder-icon-tooltip {
  max-width: 400px;
  background-color: #0a283d !important;
  opacity: 1 !important;
}

.reminder-icon-tooltip::after {
  background-color: #0a283d !important;
}

.reminder-tooltip-table {
  border-collapse: collapse;
  width: 100%;
}

.reminder-tooltip-table th {
  text-align: left;
  vertical-align: top;
  font-weight: bold;
  min-width: 100px !important;
  padding-top: 12px;
}

.reminder-tooltip-table td {
  vertical-align: top !important;
  color: white !important;
  padding-top: 12px !important;
}
