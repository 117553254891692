.success-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.success-modal-value {
  font-size: 14px;
  font-weight: 400;
}
.success-modal-value-bold {
  font-size: 14px;
  font-weight: 700;
}

.claim-detail-view {
  border-top: 1px solid #000000;
  padding-top: 20px;
  overflow-y: auto;
  overflow-x: clip;
  max-height: calc(90vh - 200px);
  padding-bottom: 40px;
  height: auto;
}
