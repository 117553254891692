.lock-filter-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lock-filter-button img {
  width: 20px;
  height: 20px;
  transition: all 0.3s ease;
}

.lock-filter-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
