.add-manual-claim-popup {
  max-width: 742px;
  transition: max-width 0.3s ease-in-out;
}

.add-manual-claim-popup:has(.claims-csv-review-table) {
  max-width: 1412px;
}

.add-manual-claim-popup .appealio-action-popup__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.add-manual-claim-popup .form-step__wrapper {
  margin-bottom: 12px;
}

.add-manual-claim-popup .close-icon {
  width: 18px;
  height: auto;
  cursor: pointer;
}

.add-manual-claim-popup .SingleDatePicker .DateInput {
  width: 85%;
}

.add-manual-claim-form-btn-wrapper .add-manual-claim-popup__clear-btn {
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  margin-right: 20px;
  cursor: pointer;
  color: #f64d31;
}

.add-manual-claim-popup__submit-btn {
  font-weight: 500;
}

.add-manual-claim-popup .autocomplete-input-container div div:nth-child(2) {
  z-index: 50;
  top: 180px !important;
  left: 16px !important;
}

.add-manual-claim-popup__tabs {
  margin-top: 16px;
  padding: 0 18px;
}

.add-manual-claim-popup__content {
  background: #e5e9ed;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
