.button-dropdown {
  background-color: #ffffff;
  color: #1f313d;
  border: 1px solid #1f313d;
  padding: 9px 18px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  min-width: 150px;
}

.button-dropdown__title-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.button-dropdown__title {
  margin: 0 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: clip;
}

.button-dropdown__menu {
  padding: 8px;
  list-style: none;
  position: absolute;
  top: 50px;
  left: 0;
  background: #ffffff;
  width: 100%;
  z-index: 9;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.button-dropdown__menu-item {
  padding: 6px;
  color: #5e6871;
  display: flex;
  align-items: flex-start;
}

.button-dropdown__menu-item label {
  cursor: pointer;
  margin-bottom: 0px;
}

.button-dropdown__menu-item label span {
  font-weight: normal;
}
