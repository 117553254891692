.ap-stepper-with-bar {
  display: flex;
  gap: 26px;
}

.ap-stepper-with-bar__wrapper {
  width: 100%;
}

.ap-stepper-with-bar__title {
  color: #000;
  font: normal 400 14px / normal 'Inter';
  font-feature-settings: 'liga' off, 'clig' off;
}

.ap-stepper-with-bar__bar {
  flex: 0 0 1;
  height: 8px;
  background-color: #d0d9e2;
  border-radius: 20px;
  margin-top: 6px;
}

.ap-stepper-with-bar__bar--active {
  background-color: #1c72f2;
}

.ap-stepper-with-bar__bar--completed {
  background-color: #41b275;
}
